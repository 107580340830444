import { Modal } from '@/components/elements';
import Button from '@/components/elements/button';
import { useSoldOutTicket } from '@/hooks/ticket';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';

type SoldOutTicketModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function SoldOutTicketModal({ open, setOpen, title, selected, refresh }: SoldOutTicketModalProps) {
    const { handleSoldOutTicket, loadingSoldOut } = useSoldOutTicket();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to Mark <b>{selected?.type}</b> Ticket as sold out ?</div>
                <Button onClick={() => {
                    handleSoldOutTicket(selected.id).then(() => {
                        setOpen(false);
                        refresh((prev) => !prev);
                    });
                }} disabled={loadingSoldOut}>{ loadingSoldOut ? <div className="py-1"><LoaderIcon /></div> : 'Confirm' }</Button>
            </div>
        </Modal>
    );
}
