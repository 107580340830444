import { PRIVATE_API } from "../axios";
import { AxiosErrorHandler, CustomError } from "@/core/utils";
import { PaymentPayload } from "@/app/types/payment";

export const payment = async (data : PaymentPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/payment', data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};