import {create_category, delete_category, edit_category, get_categories} from '@/api/categories';
import {TicketPayload} from '@/app/types/tickets';

import {useState} from 'react';
import {toast} from 'react-hot-toast';
import {useLocation} from 'react-router-dom';

export const useGetAllcategories = () => {
    const [loadingcategories, setLoadingcategories] = useState(false);
    const [categories, setcategories] = useState<any>(null);
    const location = useLocation();
    const query = location.search;

    const handleGetcategories = async () => {
        try {
            setLoadingcategories(true);

            const data = await get_categories(query);
            setcategories(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingcategories(false);
        }
    };

    return {
        loadingcategories,
        categories,
        handleGetCategories: handleGetcategories
    };
};


export const useCreatecategories = () => {
    const [loadingCreatecategories, setCreateLoading] = useState(false);

    const handleCreatecategories = async (data: { name: string, icon: string }) => {
        try {
            setCreateLoading(true);
            await create_category(data).then(() => toast.success('Category Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setCreateLoading(false);
        }
    };

    return {
        loadingCreatecategories,
        handleCreatecategories
    };
};


export const useEditcategories = () => {
    const [loadingEditcategories, setLoadingEditcategories] = useState(false);

    const handleEditCat = async (data: TicketPayload, id: string) => {
        try {
            setLoadingEditcategories(true);
            await edit_category(data, id).then(() => toast.success('category Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEditcategories(false);
        }
    };

    return {
        loadingEditcategories,
        handleEditCat
    };
};

export const useDeleteCategory = () => {
    const [loadingDeleteTicket, setDeleteLoading] = useState(false);

    const handleDeleteTicket = async (id: string) => {
        try {
            setDeleteLoading(true);
            await delete_category(id).then(() => toast.success('Category Deleted'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setDeleteLoading(false);
        }
    };

    return {
        loadingDeleteTicket,
        handleDeleteTicket
    };
};


