import {AxiosErrorHandler, CustomError} from '@/core/utils/request';
import {PRIVATE_API} from '../axios';
import {Ticket, TicketPayload} from '@/app/types/tickets';
import {PaginationType} from '@/app/types/shared';

export const get_categories = async (query?: string): Promise<PaginationType<Ticket>> => {
    try {
        const request = await PRIVATE_API.get('/category');
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const create_category = async (data: { name: string, icon: string }): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/category', data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_category = async (data: TicketPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/category/${id}`, data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_category = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/category/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
