import { UserFilterPayload, UserType } from "@/app/types/auth";
import { PaginationType } from "@/app/types/shared";
import { Modal } from "@/components/elements";
import Button from "@/components/elements/button";
import { Form } from "@/components/elements/form";
import { InputField } from "@/components/elements/input";
import { DataTable } from "@/components/elements/tables";
import { TableColumn } from "@/components/elements/tables/Datatable";
import { useGetAllUsers } from "@/hooks/auth";
import {
	CheckIcon,
	DocumentIcon,
	FunnelIcon,
	MagnifyingGlassIcon,
	TrashIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import DeleteUserModal from "./modals/delete_modal";
import DataTableV2, {
	TableColumnV2,
} from "@/components/elements/tables/Datatable/DataTable";

type UserData = {
	name: string;
	age: string;
	phoneNumber: string;
	password: string;
	address: string;
	location: string;
};

export default function DashUsers() {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<UserType | null>(null);
	const [refresh, setRefresh] = useState(false);
	const [filters, setFilters] = useState<UserFilterPayload>({
		from: "",
		to: "",
	});
	const location = useLocation();
	const query = location.search;
	const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
	useEffect(() => {
		handleGetUsers(query);
	}, [query, refresh]);
	const columns: TableColumnV2<UserType>[] = [
		{
			title: "Name",
			accessor: "name",
			render: (row) => <p>{row.name}</p>,
		},
		{
			title: "Email",
			accessor: "email",
			render: (row) => <p>{row.email}</p>,
		},
		{
			title: "Status",
			accessor: "status",
			render: (row) => <p>{row.status}</p>,
		},
		{
			title: "Phone Number",
			accessor: "phoneNumber",
			render: (row) => <p>{row.phoneNumber}</p>,
		},
		{
			title: "Role",
			accessor: "role",
			render: (row) => <p>{row.role}</p>,
		},
		{
			title: "Actions",
			accessor: "actions",
			render: (row) => (
				<div className="flex gap-2 justify-center">
					<button
						onClick={() => {
							setSelected(row);
							setOpen(true);
						}}
						className="bg-red-700 text-gray-200 p-1 rounded"
					>
						<TrashIcon className="w-4" />
					</button>
				</div>
			),
		},
	];
	return (
		<div className="">
			<DataTableV2
				columns={columns}
				previousPage={users?.previousPage ?? 0}
				nextPage={users?.nextPage ?? 0}
				currentPage={users?.currentPage ?? 0}
				data={users?.list ?? []}
				total={users?.total ?? 0}
				lastPage={users?.lastPage ?? 0}
				isLoading={loadingUsers}
				header={
					<div className="w-full flex my-3 justify-end items-center">
						<Filter
							filters={filters}
							data={users}
							onFilter={(payload: UserFilterPayload) => {
								setFilters(payload);
							}}
						/>
					</div>
				}
			/>
			<DeleteUserModal
				refresh={setRefresh}
				selected={selected}
				open={open}
				setOpen={setOpen}
				title={"Delete User"}
			/>
		</div>
	);
}

type FilterProps = {
	filters: UserFilterPayload;
	onFilter: (...args: any) => void;
	data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
	const { onFilter, filters, data } = props;
	const [open, setOpen] = useState(false);
	const [openPrint, setOpenPrint] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const schema = z.object({
		from: z.string().optional(),
		to: z.string().optional(),
		channel: z.string().optional(),
	});
	const defaultFilters: UserFilterPayload = {
		from: "",
		to: "",
	};
	const handleOnSubmit = (payload: any) => {
		const searchParams = new URLSearchParams(location.search);

		const { from, to, channel } = payload;

		if (from) searchParams.set("from", from);
		if (to) searchParams.set("to", to);
		if (channel) searchParams.set("channel", channel);

		const newSearch = searchParams.toString();
		onFilter(payload);
		navigate(`${location.pathname}?${newSearch}`);

		setOpen(false);
	};
	const resetFilter = () => {
		onFilter(defaultFilters);
		navigate(`${location.pathname}`);
		setOpen(false);
	};
	return (
		<>
			<div className="w-full justify-end flex flex-wrap">
				<div className="flex flex-row gap-3">
					<Button title="Filter results" onClick={() => setOpen(true)}>
						<FunnelIcon className="w-4" /> Filter
					</Button>
				</div>
			</div>
			<Modal show={open} setShow={setOpen} title={"Filter"}>
				<Form<UserFilterPayload, typeof schema>
					onSubmit={handleOnSubmit}
					schema={schema}
					options={{ defaultValues: filters }}
				>
					{({ register, formState }) => (
						<div className="px-3">
							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<InputField
									type="date"
									error={formState.errors.from}
									registration={register("from")}
									className="h-12"
									label="Start Date"
								/>

								<InputField
									type="date"
									error={formState.errors.to}
									registration={register("to")}
									className="h-12"
									label="End Date"
								/>
							</div>
							<div className="py-4 flex flex-row justify-between">
								<Button onClick={resetFilter} background="bg-gray-500">
									Reset
								</Button>
								<Button>Filter</Button>
							</div>
						</div>
					)}
				</Form>
			</Modal>
			{/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
		</>
	);
}
