import {
    create_tickets,
    delete_ticket,
    edit_tickets,
    generate_tickets,
    get_batch_names,
    get_tickets,
    get_tickets_exported,
    get_tickets_online,
    sold_out_ticket
} from '@/api/tickets';
import { PaginationType } from '@/app/types/shared'
import { GenerateTicketPayload, Ticket, TicketPayload } from '@/app/types/tickets';
import {useEffect, useState} from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export const useGetAllTickets = () => {
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [tickets, setTickets] = useState<PaginationType<Ticket> | null>(null);
    const location = useLocation();
    const query = location.search;
    const handleGetTickets = async () => {
        try {
            setLoadingTickets(true);
            const data: PaginationType<Ticket> = await get_tickets(query);
            setTickets(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingTickets(false);
        }
    };

    return {
        loadingTickets,
        tickets,
        handleGetTickets
    };  
};

export const useGetAllBatchNames = () => {
    const [loadingBatchNames, setLoadingBatchNames] = useState(false);
    const [batchNames, setBatchNames] = useState<{ uniqueDescriptions: string[] } | null>(null);
    const handleGetBatchNames = async () => {
        try {
            setLoadingBatchNames(true);
            const data: { uniqueDescriptions: string[] } = await get_batch_names();
            setBatchNames(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingBatchNames(false);
        }
    };

    useEffect(() => {
        handleGetBatchNames().then(() => null);
    }, []);

    return {
        loadingBatchNames,
        batchNames
    };
};

export const useGetAllTicketsExported = () => {
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [tickets, setTickets] = useState<PaginationType<Ticket> | null>(null);
    const handleGetTickets = async ( res : {from: number, to: number, id: string, size: string, description: string}) => {
        try {
            setLoadingTickets(true);
            const data: PaginationType<Ticket> = await get_tickets_exported(res);
            setTickets(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingTickets(false);
        }
    };

    return {
        loadingTickets,
        tickets,
        handleGetTickets
    };  
};

export const useGetAllTicketsOnline = () => {
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [tickets, setTickets] = useState<PaginationType<Ticket> | null>(null);
    const location = useLocation();
    const query = location.search;
    const handleGetTickets = async () => {
        try {
            setLoadingTickets(true);
            const data: PaginationType<Ticket> = await get_tickets_online(query);
            setTickets(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingTickets(false);
        }
    };

    return {
        loadingTickets,
        tickets,
        handleGetTickets
    };  
};


export const useCreateTickets = () => {
    const [loadingCreateTickets, setCreateLoading] = useState(false);

    const handleCreateTickets = async (data: TicketPayload) => {
        try {
            setCreateLoading(true);
            await create_tickets(data).then(() => toast.success('Ticket Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setCreateLoading(false);
        }
    };

    return {
        loadingCreateTickets,
        handleCreateTickets
    };  
};

export const useGenerateTickets = () => {
    const [loadingGenerateTickets, setGenerateLoading] = useState(false);

    const handleGenerate = async (data: GenerateTicketPayload) => {
        try {
           setGenerateLoading(true);
           await generate_tickets(data);
           toast.success('Tickets Generated');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setGenerateLoading(false);
        }
    };

    return {
        loadingGenerateTickets,
        handleGenerate,
    };  
};

export const useEditTickets = () => {
    const [loadingEditTickets, setLoadingEditTickets] = useState(false);

    const handleEditTicket = async (data: TicketPayload, id: string) => {
        try {
            setLoadingEditTickets(true);
            await edit_tickets(data, id).then(() => toast.success('Ticket Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEditTickets(false);
        }
    };

    return {
        loadingEditTickets,
        handleEditTicket
    };  
};

export const useDeleteTicket = () => {
    const [loadingDeleteTicket, setDeleteLoading] = useState(false);

    const handleDeleteTicket = async (id: string) => {
        try {
            setDeleteLoading(true);
            await delete_ticket(id).then(() => toast.success('Ticket Deleted'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setDeleteLoading(false);
        }
    };

    return {
        loadingDeleteTicket,
        handleDeleteTicket
    };  
};

export const useSoldOutTicket = () => {
    const [loadingSoldOut, setSoldOutLoading] = useState(false);

    const handleSoldOutTicket = async (id: string) => {
        try {
            setSoldOutLoading(true);
            await sold_out_ticket(id).then(() => toast.success('Ticket Sold Out'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setSoldOutLoading(false);
        }
    };

    return {
        loadingSoldOut,
        handleSoldOutTicket
    };  
};
