import { TicketPayload } from '@/app/types/tickets';
import { TicketType } from '@/app/types/tickets_types';
import Button from '@/components/elements/button';
import { Form } from '@/components/elements/form';
import { InputField, SelectField } from '@/components/elements/input';
import { useGetAllEvents } from '@/hooks/events';
import { useCreateTickets } from '@/hooks/ticket';
import { useGetAllTicketsType } from '@/hooks/ticket_type';
import { useEffect } from 'react';
import { z } from 'zod';

const schema = z.object({
    eventId: z.string().min(1, { message: 'Event is required' }),
    price: z.string().min(1, { message: 'Price is required' }),
    type: z.string().min(1, { message: 'Type is required' }),
    pre_sell: z.string().min(1, { message: 'Pre-Sell are required' }),
    available_ticket: z.string().min(1, { message: 'Available Tickets are required' }),
});

function FormatSelectOptions(arr: any[]) {
    const result = arr.map((item) => ({
        value: item.id,
        text: item.title
    }));
    return result;
}


export default function CreateTicket() {
    const { handleGetEvents, events } = useGetAllEvents();
    const { handleGetTickets, tickets } = useGetAllTicketsType();
    const { loadingCreateTickets, handleCreateTickets  } = useCreateTickets();
    function FormatSelectTicketsOptions(arr: TicketType[]) {
        const result = arr.map((item) => ({
            value: item.id,
            text: item.type
        }));
        return result;
    }
    useEffect(() => {
        handleGetEvents('', '');
        handleGetTickets();
    }, []);
    return (
        <div className="h-screen">
            <div className="panel mt-5">
                <div className="w-full px-5">
                    <Form<TicketPayload, typeof schema> onSubmit={(data) => handleCreateTickets(data)} schema={schema}>
                        {({ register, watch, formState }) => (
                            <div className="w-full">
                                <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            label="Event"
                                            options={FormatSelectOptions(events?.list || [])}
                                            placeholder="Select Event"
                                            error={formState.errors.eventId}
                                            registration={register('eventId')}
                                        />
                                        <SelectField
                                            label="Ticket Type"
                                            options={FormatSelectTicketsOptions(tickets?.list || [])}
                                            placeholder="Select Ticket Type"
                                            error={formState.errors.pricingId}
                                            registration={register('pricingId')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreateTickets}
                                            label="Phone Number"
                                            error={formState.errors.phoneNumber}
                                            registration={register('phoneNumber')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            label="Names"
                                            isLoading={loadingCreateTickets}
                                            type="text"
                                            error={formState.errors.names}
                                            registration={register('names')}
                                        />
                                        <InputField
                                            type="number"
                                            isLoading={loadingCreateTickets}
                                            label="Quantity"
                                            error={formState.errors.quantity}
                                            registration={register('quantity')}
                                        />
                                    </div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreateTickets}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
