import { InputField, SelectField } from "@/components/elements/input";
import { FormatTicketTypeSelectOptions } from "@/core/utils";
import { useGetEventsBySlug } from "@/hooks/events";
import {
  EnvelopeIcon,
  LockClosedIcon,
	MapPinIcon,
	PencilIcon,
	PhoneIcon,
	TicketIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { z } from "zod";
import { PaymentPayload } from "@/app/types/payment";
import { Form } from "@/components/elements/form";
import { LoaderIcon } from "react-hot-toast";
import { usePay } from "@/hooks/payment";

const schema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  pricingId: z.string().min(1, { message: 'Ticket is required' }),
  email: z.string().min(1, { message: 'Email is required' }),
  ticket_number: z.string().min(1, { message: 'Ticket number is required' }),
  phoneNumber: z.string().refine(
    value => {
        return value.startsWith('2507') && value.length === 12;
    },
    { message: 'Phone number must be inform of 2507........' }
  ),
});


export default function PaymentPage() {
	const { event, loadingEvent } = useGetEventsBySlug();
  const { handlePay, loadingPay } = usePay();
  const defaultValues = {
    phoneNumber: '',
    pricingId: '',
    name: '',
    ticket_number: 0,
    email: ''
  }
  
	return (
		<div className="">
			{loadingEvent ? <div className="w-screen h-screen bg-primary flex justify-center items-center"><LoaderIcon style={{ width: '50px', height: '50px' }} /></div> : <div className="grid grid-col-1 md:grid-cols-2 capitalize">
				<div className=" md:h-screen bg-primary "
          style={{
            background: event?.image ? `url(${event?.image})` : '#fff',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center'
          }}
        >
          <div className={`w-full h-full px-10 pt-10 ${event?.image ? `backdrop-brightness-50 text-white` : 'text-black'}`}>
            <div className="overflow-y-scroll h-full">
              <div className=" py-5 border-b text-3xl font-bold ">
                {event?.title}
              </div>
              <div className="py-2  capitalize flex flex-col gap-2">
                <div className="flex flex-row font-bold gap-2">
                  <PencilIcon className="w-5" /> Description
                </div>
                <p className="pl-10 ">{event?.description}</p>
              </div>
              <div className="py-2 capitalize flex flex-col gap-2">
                <div className="flex flex-row font-bold gap-2">
                  <MapPinIcon className="w-5" /> Location
                </div>
                <p className="pl-10">{event?.location}</p>
              </div>
              <div className="flex py-2 flex-row font-bold gap-2">
                <TicketIcon className="w-5" /> Tickets
              </div>
              <div className="py-2 text-gray-200  capitalize flex flex-col gap-1">
                {event?.pricing.map((item, index) => (
                  <div
                    key={index}
                    className="py-4 my-2 bg-primary flex justify-between items-center px-5 rounded"
                  >
                    <p>{item.type}</p>
                    <p>{item.price} RWF</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
				</div>
				<div className="w-full">
					<div className="p-7  h-screen flex flex-col pt-20 ">
						<Form<PaymentPayload, typeof schema>
							onSubmit={(data) => handlePay({...data, payment_mode: 'momo'})}
							schema={schema}
              options={{
                defaultValues
              }}
						>
							{({ register, formState, reset }) => (
								<div className="w-full border border-gray-100 shadow-md pt-10 py-5">
									<div className="px-2 flex flex-col gap-4 text-sm mb-3">
										<div className="flex flex-col md:flex-row gap-2">
                      <InputField
												type="text"
												isLoading={loadingPay}
												label="Name"
                        icon={<UserIcon className="w-4" />}
												error={formState.errors.name}
												registration={register('name')}
											/>
										</div>
                    <div className="flex flex-col md:flex-row gap-2">
											<InputField
												type="number"
												isLoading={loadingPay}
												label="Phone number"
                        icon={<PhoneIcon className="w-4" />}
												error={formState.errors.phoneNumber}
												registration={register('phoneNumber')}
											/>
                      <InputField
												type="email"
												isLoading={loadingPay}
												label="Email"
                        icon={<EnvelopeIcon className="w-4" />}
												error={formState.errors.email}
												registration={register('email')}
											/>
										</div>
										<div className="flex flex-col md:flex-row gap-2">
                      <SelectField
												label="Ticket"
                        isLoading={loadingPay}
												options={FormatTicketTypeSelectOptions(event?.pricing || [])}
												placeholder="Select Ticket"
                        icon={<TicketIcon className="w-4" />}
												error={formState.errors.pricingId}
												registration={register("pricingId")}
											/>
											<InputField
												type="number"
												isLoading={loadingPay}
                        icon={<TicketIcon className="w-4" />}
												label="Number of Tickets"
												error={formState.errors.ticket_number}
												registration={register("ticket_number")}
											/>
                    
										</div>
										<div className="my-3 flex justify-end">
											<button disabled={loadingPay} className="w-full flex justify-center items-center rounded py-3 bg-primary text-gray-200">
                          { loadingPay ? <LoaderIcon /> : <><LockClosedIcon className="w-5 mr-3" /> Pay Now</>}
                      </button>
										</div>
									</div>
								</div>
							)}
						</Form>
					</div>
				</div>
			</div>}
		</div>
	);
}
