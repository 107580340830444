
import { Modal } from '@/components/elements';
import Button from '@/components/elements/button';
import { useGetAllEvents } from '@/hooks/events';
import React, { SetStateAction, useEffect } from 'react';
import { Form } from '@/components/elements/form';
import { InputField, SelectField } from '@/components/elements/input';
import { useState } from 'react';
import { z } from 'zod';
import { TicketTypePayload } from '@/app/types/tickets_types';
import { useEditTicketsTypes } from '@/hooks/ticket_type';
import {EventType} from "@/app/types/events";

type EditTicketModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    event: EventType;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function EditTicketModal({ open, setOpen, title,event, selected, refresh }: EditTicketModalProps) {
    return (
        <Modal size='lg' show={open}  setShow={setOpen} title={title} >
            <EditTicket id={selected?.id} event={event} defaultValues={selected} setRefresh={refresh} setOpen={setOpen} />
        </Modal>
    );
}

const schema = z.object({
    design: z.string().min(1, { message: 'Design is required' }),
    price: z.union([z.string().min(1, { message: 'Price is required' }), z.number().min(1, { message: 'Price must be a number and is required' })]),
    type: z.string().min(1, { message: 'Type is required' }),
    pre_sell: z.union([z.string().min(1, { message: 'Pre-Sell is required' }), z.number().min(1, { message: 'Pre-Sell must be a number and is required' })]),
    available_ticket: z.union([z.string().min(1, { message: 'Available Tickets are required' }), z.number().min(1, { message: 'Available Tickets must be a number and is required' })]),
});

function FormatSelectOptions(arr: any[]) {
    const result = arr.map((item) => ({
        value: item.id,
        text: item.title
    }));
    return result;
}

type EditTicketProps = {
    id: string;
    event: EventType;
    defaultValues: TicketTypePayload;
    setRefresh: React.Dispatch<SetStateAction<boolean>>;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
}

function EditTicket({ id, defaultValues, setOpen,event, setRefresh } : EditTicketProps) {
    const { handleGetEvents, events } = useGetAllEvents();
    const { loadingEditTickets, handleEditTicket  } = useEditTicketsTypes();
    const [image, setImage] = useState('');
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    console.log(e.target.result);
                    setImage(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
        console.log(image);
    };
    useEffect(() => {
        handleGetEvents('', '');
    }, []);
    return (
        <div className="">
            <div className="panel">
                <div className="w-full">
                    <Form<TicketTypePayload, typeof schema> options={{ defaultValues }} 
                    onSubmit={(data) => handleEditTicket({...data, eventId: event.id, image: image}, id).then(() => { setRefresh((prev) => !prev); setOpen(false); })} schema={schema}>
                        {({ register, formState }) => (
                            <div className="w-full">
                                <div className=" flex flex-col gap-4 text-sm mb-3">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingEditTickets}
                                            label="Ticket Type"
                                            error={formState.errors.type}
                                            registration={register('type')}
                                        />
                                        <InputField
                                        
                                            type="number"
                                            isLoading={loadingEditTickets}
                                            label="Price"
                                            error={formState.errors.price}
                                            registration={register('price')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            label="Pre-Sold"
                                            isLoading={loadingEditTickets}
                                            type="number"
                                            error={formState.errors.pre_sell}
                                            registration={register('pre_sell')}
                                        />
                                        <InputField
                                            type="number"
                                            isLoading={loadingEditTickets}
                                            label="Available Tickets"
                                            error={formState.errors.available_ticket}
                                            registration={register('available_ticket')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            label="Design"
                                            placeholder="Select Design"
                                            error={formState.errors.design}
                                            registration={register('design')}
                                            options={[
                                                {
                                                    value: "small",
                                                    text: "Small",
                                                },
                                                {
                                                    value: "large",
                                                    text: "Large",
                                                },
                                            ]}
                                        />
                                        <InputField
                                            type="file"
                                            isLoading={loadingEditTickets}
                                            label="Image"
                                            placeholder="Choose Image"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingEditTickets}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
