import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PRIVATE_API } from '../axios';
import { TicketTypePayload, TicketType } from '@/app/types/tickets_types';
import { PaginationType } from '@/app/types/shared';
import { queryString } from '@/core/utils';

export const get_tickets_types = async (query? : string): Promise<PaginationType<TicketType>> => {
    try {
        const request = await PRIVATE_API.get(`/ticket_type${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_tickets_types_by_event = async (id: string, query? : string): Promise<PaginationType<TicketType>> => {
    try {
        const request = await PRIVATE_API.get(`/ticket_type?eventId=${id}${queryString(query)}&pageSize=100`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_tickets_types = async (data : TicketTypePayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/ticket_type', data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_tickets_types = async (data : TicketTypePayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/ticket_type/${id}`, data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_ticket_types = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/ticket_type/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const sold_out_ticket_types = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(`/ticket_type/soldout/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const scan_ticket = async (id : string): Promise<TicketType> => {
    try {
        const request = await PRIVATE_API.post(`/ticket/scan/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};