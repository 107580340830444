import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storage } from '@/core/utils';
import { check_user } from '@/api/auth';
import { useLogout } from '@/hooks/auth';
import { addUser } from '../redux/slices/user/userAccountSlice';

export const CheckUser = () => {
    const token = storage.getToken();
    const dispatch = useDispatch();
    const { handleLogout } = useLogout();

    const fetchUser = async () => {
        if (token) {
            try {
                const user = await check_user();
                dispatch(addUser(user));
            } catch (error) {
                handleLogout();
            }
        } else {
            handleLogout();
        }
    };

    useEffect(() => {
        fetchUser();
    });
};
