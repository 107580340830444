import {InvitationPayload} from '@/app/types/invitations';
import Button from '@/components/elements/button';
import {Form} from '@/components/elements/form';
import {InputField, SelectField} from '@/components/elements/input';
import {useGetAllEvents} from '@/hooks/events';
import {useCreateInvitation} from '@/hooks/invitations';
import {useEffect, useState} from 'react';
import {z} from 'zod';

const schema = z.object({
    eventId: z.string().min(1, {message: 'Event is required'}),
    title: z.string().min(1, {message: 'Title is required'}),
    quantity: z.string().min(1, {message: 'Quantity is required'}),
    design: z.string().min(1, {message: 'Design is required'}),
});

function FormatSelectOptions(arr: any[]) {
    const result = arr.map((item) => ({
        value: item.id,
        text: item.title
    }));
    return result;
}

export default function CreateInvitation() {
    const {handleGetEvents, events} = useGetAllEvents();
    const {handleCreateInvitations, loadingCreateInvitations} = useCreateInvitation();
    const [image, setImage] = useState('');
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    console.log(e.target.result);
                    setImage(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
        console.log(image);
    };
    useEffect(() => {
        handleGetEvents('', '');
    }, []);
    return (
        <div className="h-screen mt-5 flex justify-center items-start">
            <div className="panel  w-full md:w-4/5">
                <div className="w-full">
                    <Form<InvitationPayload, typeof schema>
                        onSubmit={(data, e) => handleCreateInvitations({
                            ...data,
                            image: image
                        }).then(() => e?.target.reset())}
                        schema={schema}>
                        {({register, formState}) => (
                            <div className="w-full">
                                <div className="">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            label="Event"
                                            options={FormatSelectOptions(events?.list || [])}
                                            placeholder="Select Event"
                                            error={formState.errors.eventId}
                                            registration={register('eventId')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreateInvitations}
                                            label="Title"
                                            error={formState.errors.title}
                                            registration={register('title')}
                                        />
                                        <InputField

                                            type="number"
                                            isLoading={loadingCreateInvitations}
                                            label="Quantity"
                                            error={formState.errors.quantity}
                                            registration={register('quantity')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            label="Design"
                                            placeholder="Select Design"
                                            error={formState.errors.design}
                                            registration={register('design')}
                                            options={[
                                                {
                                                    value: "small",
                                                    text: "Small",
                                                },
                                                {
                                                    value: "large",
                                                    text: "Large",
                                                },
                                            ]}
                                        />
                                        <InputField
                                            type="file"
                                            isLoading={loadingCreateInvitations}
                                            label="Image"
                                            placeholder="Choose Image"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreateInvitations}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
