import { GenerateTicketPayload } from "@/app/types/tickets";
import Button from "@/components/elements/button";
import { Form } from "@/components/elements/form";
import { InputField, SelectField } from "@/components/elements/input";
import { useGetAllEvents } from "@/hooks/events";
import {useGenerateTickets, useGetAllBatchNames} from "@/hooks/ticket";
import { useGetAllTicketsType } from "@/hooks/ticket_type";
import { TicketIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { z } from "zod";
import Select from "react-select";

const schema = z.object({
	pricingId: z.string().min(1, "Ticket Type is required"),
	quantity: z.string().min(1, "Quantity is required"),
	description: z.string().min(1, "Description is required")
});


export default function GenerateTickets() {
    const { handleGetEvents, events, loadingEvents } = useGetAllEvents();
	const { loadingGenerateTickets, handleGenerate } = useGenerateTickets();
	const { handleGetTickets, tickets, loadingTickets } = useGetAllTicketsType();
    const [selectedId, setSelectedId] = useState<string | null>(null);

    const handleEventChange = (e: any) => {
        handleGetTickets();
        setSelectedId(e.value);
    }

    useEffect(() => {
        handleGetEvents('', '');
    }, []);

	return (
		<div className="h-screen">
			<div className="panel mt-5">
				<div className="w-full flex border-b border-gray-400 dark:border-gray-700">
					<div className="px-4 flex flex-row gap-2  pb-5 items-center">
						<TicketIcon className="w-5" /> Generate Tickets
					</div>
				</div>
				<div className="w-full mt-4">
					<div className="px-4 py-3 flex gap-2 flex-col">
						<Form<GenerateTicketPayload, typeof schema>
							onSubmit={handleGenerate}
						>
							{({ register, formState, watch, setValue }) => (
								<div className="flex-col flex gap-2">
									<div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
										<Select
											placeholder="Select Event"
                                            isLoading={loadingEvents}
											options={events?.list.map((item) => {
												return {
													label: item.title,
													value: item.id
												}
											})}
                                            onChange={handleEventChange}
										/>
                                            {/*error={formState.errors.pricingId}*/}
										<Select
											placeholder="Select Ticket"
											isLoading={loadingTickets || tickets === null || watch('pricingId') === null}
											// registration={register("pricingId")}
											onChange={(val) => setValue('pricingId', val?.value ?? '')}
											options={tickets?.list.filter((value) => value.event.id === selectedId).map((item) => {
												return {
													label: item.type,
													value: item.id
												}
											})}
										/>
										<InputField
											type="number"
											error={formState.errors.quantity}
											registration={register("quantity")}
											placeholder="Quantity"
										/>
										<InputField
											type="text"
											error={formState.errors.description}
											registration={register("description")}
											placeholder="Enter unique description"
										/>
									</div>
									<div className="">
										<Button disabled={loadingGenerateTickets} title="Generate tickets">Submit</Button>
									</div>
								</div>
							)}
						</Form>
					</div>
                    <div className="p-5 flex justify-center items-center">
                        {loadingGenerateTickets ? <LoaderIcon style={{ height: '30px', width: '30px' }} /> : ''}
                    </div>
				</div>
			</div>
		</div>
	);
}
