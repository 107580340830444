import { CheckUser } from '@/core/utils/useUser';
// import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

export const AccountLayout = () => {
    // useAuthentication({ isAuth: true });

    // const { userAccount } = useSelector((state: StoreType) => state.user);

    CheckUser();

    return (
        <>
            <main className="">
                <Outlet />
            </main>
        </>
    );
};
