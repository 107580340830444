import {EventPayload} from "@/app/types/events";
import Button from "@/components/elements/button";
import {Form} from "@/components/elements/form";
import {InputField, TextArea} from "@/components/elements/input";
import {useAppSelector} from "@/core/redux/store/types";
import {useGetAllcategories} from "@/hooks/Categories";
import {useCreateEvent} from "@/hooks/events";
import {CalendarDaysIcon, NewspaperIcon,} from "@heroicons/react/24/outline";
import React, {SetStateAction, useEffect, useState} from "react";
import {FormState, UseFormRegister, UseFormSetValue} from "react-hook-form";
import {LoaderIcon} from "react-hot-toast";
import {z} from "zod";
import CreateTicketTypesStep from "@/components/pages/home/dashboard/tickets/create/create_ticket";
import Select from "react-select";

const schema = z.object({
    title: z.string().min(1, {message: "Title is required"}),
    phoneNumber: z.string().refine(
        (value) => {
            return value.startsWith("2507") && value.length === 12;
        },
        {message: "Phone number must be inform of 2507........"}
    ),
    // category_id: z.string().min(1, {message: 'category is required'}),
    organiser: z.string().min(1, {message: "Organizer is required"}),
    location: z.string().min(1, {message: "Location is required"}),
    country: z.string().min(1, {message: "Country is required"}),
    description: z.string().min(1, {message: "Description is required"}),
    currency: z.string().min(1, {message: "Currency is required"}),
    date: z.string().min(1, {message: "Date is required"}),
    endDate: z.string().min(1, {message: "End Date is required"}),
    tags: z.string().min(1, {message: "Minimum of 1 tag required"}),
    channel: z.number(),
    ishema_percentage: z.string().min(1, { message: 'Ishema percent is required' })
});

function    EventDetails(props: {
    setTab: React.Dispatch<SetStateAction<number>>;
    register: UseFormRegister<EventPayload>;
    formState: FormState<EventPayload>;
    image: string;
    setValue: UseFormSetValue<EventPayload>;
    setImage: React.Dispatch<SetStateAction<string>>;
    loading: boolean;
}) {
    const {register, formState,setValue, image, setImage, loading} = props;
    const {handleGetCategories, categories, loadingcategories} = useGetAllcategories();

    const {user} = useAppSelector((state) => state);

    const {userAccount} = user;
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    setImage(e.target.result as string);
                    register("image");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    return (
        <div>
            <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <InputField
                        type="text"
                        label="Event Title"
                        error={formState.errors.title}
                        registration={register("title")}
                    />
                    <div className="flex flex-col">
                        <label>Channel</label>
                        <Select
                            onChange={(e) => setValue('channel', e?.value ?? 1)}

                            options={[{ label: 'Ishema Tickets', value: 1 }, { label: 'Ishusho Tickets', value: 2 }]} />
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*    <label*/}

                    {/*        className="block mb-1 text-sm font-medium text-gray-600 dark:text-white-light"*/}
                    {/*    >*/}
                    {/*        Select Category*/}
                    {/*    </label>*/}
                    {/*    <select*/}
                    {/*        id="category"*/}
                    {/*        className="form-select"*/}
                    {/*        {...register('category_id', {*/}
                    {/*            required: 'Category ID is required',*/}
                    {/*        })}*/}
                    {/*    >*/}
                    {/*        <option value="" disabled>*/}
                    {/*            Select category*/}
                    {/*        </option>*/}
                    {/*        {loadingcategories ? (*/}
                    {/*            <option value="" disabled>*/}
                    {/*                Loading categories...*/}
                    {/*            </option>*/}
                    {/*        ) : categories?.length === 0 ? (*/}
                    {/*            <option value="" disabled>*/}
                    {/*                No categories found*/}
                    {/*            </option>*/}
                    {/*        ) : (*/}
                    {/*            categories?.map((category: { id: string | any, name: string | any }) => (*/}
                    {/*                <option*/}
                    {/*                    key={category.id}*/}
                    {/*                    value={category.id}*/}
                    {/*                >*/}
                    {/*                    {category.name}*/}
                    {/*                </option>*/}
                    {/*            ))*/}
                    {/*        )}*/}
                    {/*    </select>*/}

                    {/*</div>*/}
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="text"
                        label="Event Organizer"
                        error={formState.errors.organiser}
                        registration={register("organiser")}
                    />
                    <InputField
                        type="text"
                        label="Phone Number"
                        error={formState.errors.phoneNumber}
                        registration={register("phoneNumber")}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                        <InputField
                            type="text"
                            label="Event Country"
                            error={formState.errors.country}
                            registration={register("country")}
                        />
                        <InputField
                            type="text"
                            label="Event Location"
                            error={formState.errors.location}
                            registration={register("location")}
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="datetime-local"
                        label="Start Date"
                        error={formState.errors.date}
                        registration={register("date")}
                    />
                    <InputField
                        type="datetime-local"
                        label="End Date"
                        error={formState.errors.endDate}
                        registration={register("endDate")}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="text"
                        label="Currency"
                        error={formState.errors.currency}
                        registration={register("currency")}
                    />
                    <InputField
                        type="number"
                        label="Ishema Percent"
                        error={formState.errors.ishema_percentage}
                        registration={register("ishema_percentage")}
                    />
                    <InputField
                        type="text"
                        label="Tags"
                        placeholder="Add Tag"
                        error={formState.errors.tags}
                        registration={register("tags")}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="file"
                        label="Event Image"
                        placeholder="Choose File"
                        onChange={handleImageChange}
                    />
                    <TextArea
                        label="Short Description"
                        error={formState.errors.description}
                        registration={register("description")}
                    />
                </div>
                <div className="my-3 flex justify-end">
                    <Button disabled={loading} onClick={() => {
                    }} background="bg-primary">
                        {loading ? 'Loading': "Create"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default function CreateEvent() {
    const [tabIndex, setTabIndex] = useState(0);
    const {handleCreateEvent, loadingCreateEvent} = useCreateEvent();
    const [image, setImage] = useState("");

    const [eventId, setEventId] = useState('');

    const tabs = [
        {
            icon: <CalendarDaysIcon className="icon-md w-3"/>,
            title: "Details",
        },
        {
            icon: <NewspaperIcon className="icon-md w-3"/>,
            title: "Tickets",
        },
    ];

    console.log(eventId);
    return (
        <div className="panel mt-5">
            <div className="relative z-[1] mx-8">
                <div
                    style={{
                        width: `${`${Math.floor(
                            (100 / tabs.length) * (tabIndex + 1) - 26
                        ).toString()}%`}`,
                    }}
                    className={` bg-primary h-1 absolute ltr:left-0 rtl:right-0 top-[20px] m-auto -z-[1] transition-[width]`}
                ></div>
                <div className="grid grid-cols-2">
                    {tabs.map((tab, index) => (
                        <li
                            key={index}
                            className="mx-auto flex flex-col items-center justify-center"
                        >
                            <button
                                type="button"
                                className={`${
                                    (tabIndex > index || tabIndex === index)
                                        ? "!border-primary !bg-primary text-white"
                                        : ""
                                }
                            border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-10 h-10 rounded-full`}
                                onClick={() => setTabIndex(1)}
                            >
                                {tab.icon}
                            </button>
                            <span
                                className={`${
                                    tabIndex === index ? "text-primary " : ""
                                }text-center block mt-2`}
                            >
								{tab.title}
							</span>
                        </li>
                    ))}
                </div>
            </div>
            <div className="p-4 w-full">
                <Form<EventPayload, typeof schema>
                    onSubmit={async (data, e) => {
                        const id = await handleCreateEvent({...data, channel: data.channel, image: image});
                        if (id && id != '') {
                            setImage('');
                            setEventId(id || '');
                            setTabIndex(1);
                            e?.target.reset();
                        }
                    }}
                    schema={schema}
                >
                    {({register, formState, setValue}) => (
                        <div className="">
                            <div className={`${tabIndex === 0 ? "block" : "hidden"}`}>
                                <EventDetails
                                    register={register}
                                    formState={formState}
                                    setTab={setTabIndex}
                                    image={image}
                                    setValue={setValue}
                                    loading={loadingCreateEvent}
                                    setImage={setImage}
                                />
                            </div>
                            {/* <div className={`${tabIndex === 2 ? 'block' : 'hidden'}`}>
                                <EventTickets
                                    loading={loadingCreateEvent}
                                    image={image}
                                    setImage={setImage}
                                    register={register}
                                    formState={formState}
                                    setTab={setTabIndex}
                                />
                            </div> */}
                        </div>
                    )}
                </Form>
                <div className={`${tabIndex === 1 ? "block" : "hidden"}`}>
                    <CreateTicketTypesStep eventId={eventId}/>
                </div>
            </div>
        </div>
    );
}

// function TicketForm() {
// 	return (
// 		<div className="flex flex-col md:flex-row border-b pb-3 gap-2">
// 			<InputField type="text" label="Ticket Type" />
// 			<InputField type="number" label="Ticket Price" />
// 			<InputField type="number" label="Ticket Pre-Sell" />
// 			<InputField type="number" label="Ticket Image" />
// 			<SelectField
// 				label="Design"
// 				placeholder="Select Design"
// 				options={[
// 					{
// 						value: "Small",
// 						text: "Small",
// 					},
// 					{
// 						value: "Large",
// 						text: "Large",
// 					},
// 					{
// 						value: "BookLet",
// 						text: "BookLet",
// 					},
// 				]}
// 			/>
// 		</div>
// 	);
// }
