import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';
import clsx from 'clsx';

type TextAreaProps = FieldWrapperPassThroughProps & {
	placeholder?: string;
	className?: string;
	registration?: Partial<UseFormRegisterReturn>;
};

export default function TextArea(props: TextAreaProps) {
    const {
        label,
        icon,
        placeholder = '',
        error,
        registration,
        isLoading,
        className = '',
    } = props;

    return (
        <FieldWrapper label={label} icon={icon} error={error} isLoading={isLoading}>
            <div className="flex w-full flex-row items-center justify-center">
                <textarea
                    autoComplete="off"
                    placeholder={placeholder}
                    disabled={isLoading}
                    className={clsx(
                        'form-input',
                        icon ? 'px-0' : 'pl-3',
                        className
                    )}
                    {...registration}
                />
            </div>
        </FieldWrapper>
    );
}
