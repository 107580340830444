import { Modal } from '@/components/elements';
import Button from '@/components/elements/button';
import { useDeleteTicket } from '@/hooks/ticket';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';

type DeleteTicketModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function DeleteTicketModal({ open, setOpen, title, selected, refresh }: DeleteTicketModalProps) {
    const { handleDeleteTicket, loadingDeleteTicket } = useDeleteTicket();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to delete <b>{selected?.type}</b> Ticket ?</div>
                <Button onClick={() => {
                    handleDeleteTicket(selected.id).then(() => {
                        setOpen(false);
                        refresh((prev) => !prev);
                    });
                }} disabled={loadingDeleteTicket}>{ loadingDeleteTicket ? <div className="py-1"><LoaderIcon /></div> : 'Delete' }</Button>
            </div>
        </Modal>
    );
}
