import { create_invitation } from "@/api/invitations";
import { InvitationPayload } from "@/app/types/invitations";
import { useState } from "react";
import { toast } from "react-hot-toast";

export const useCreateInvitation = () => {
    const [loadingCreateInvitations, setCreateLoading] = useState(false);

    const handleCreateInvitations = async (data: InvitationPayload) => {
        try {
            setCreateLoading(true);
            await create_invitation(data).then(() => toast.success('Invitation Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setCreateLoading(false);
        }
    };

    return {
        loadingCreateInvitations,
        handleCreateInvitations
    };  
};