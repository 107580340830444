import {TicketTypePayload} from '@/app/types/tickets_types';
import Button from '@/components/elements/button';
import {Form} from '@/components/elements/form';
import {InputField, SelectField} from '@/components/elements/input';
import {useCreateTicketsTypes} from '@/hooks/ticket_type';
import {CameraIcon} from '@heroicons/react/24/outline';
import React, {SetStateAction, useState} from 'react';
import {z} from 'zod';

const schema = z.object({
    price: z.string().min(1, {message: 'Price is required'}),
    type: z.string().min(1, {message: 'Type is required'}),
    design: z.string().min(1, {message: 'Design is required'}),
    pre_sell: z.string().min(1, {message: 'Pre-Sell are required'}),
    available_ticket: z.string().min(1, {message: 'Available Tickets are required'}),
});


export default function CreateTicketTypesStep({ eventId }: { eventId: string }) {
    const {loadingCreateTickets, handleCreateTickets} = useCreateTicketsTypes();
    const [image, setImage] = useState('');
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    console.log(e.target.result);
                    setImage(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <div className="">
            <div className="panel mt-5">
                <div className="w-full px-5 overflow-y-scroll scrollbar-hidden ">
                    <Form<TicketTypePayload, typeof schema>
                        onSubmit={(data, e) => handleCreateTickets({
                            ...data,
                            image: image,
                            eventId: eventId
                        }).then(() => {
                            e?.target.reset();
                            setImage('');
                        })}
                        schema={schema}>
                        {({register, formState, watch}) => (
                            <div className="w-full">
                                <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreateTickets}
                                            label="Ticket Type"
                                            error={formState.errors.type}
                                            registration={register('type')}
                                        />
                                        <InputField

                                            type="number"
                                            isLoading={loadingCreateTickets}
                                            label="Price"
                                            error={formState.errors.price}
                                            registration={register('price')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            label="Pre-Sold"
                                            isLoading={loadingCreateTickets}
                                            type="number"
                                            error={formState.errors.pre_sell}
                                            registration={register('pre_sell')}
                                        />
                                        <InputField
                                            type="number"
                                            isLoading={loadingCreateTickets}
                                            label="Available Tickets"
                                            error={formState.errors.available_ticket}
                                            registration={register('available_ticket')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            label="Design"
                                            placeholder="Select Design"
                                            error={formState.errors.design}
                                            registration={register('design')}
                                            options={[
                                                {
                                                    value: "small",
                                                    text: "Small",
                                                },
                                                {
                                                    value: "large",
                                                    text: "Large",
                                                },
                                            ]}
                                        />
                                        <InputField
                                            type="file"
                                            isLoading={loadingCreateTickets}
                                            label="Image"
                                            placeholder="Choose Image"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div
                                        className="p-1 border rounded flex justify-center items-center border-dashed border-indigo-700 bg-gray-200">{image != '' ?
                                        <img src={image}
                                             className={`w-full ${watch('design') === 'large' ? 'max-h-[200px]' : 'w-[297px] '}`}
                                             alt={'Selected Image'}/> :
                                        <div className="flex flex-row gap-2 text-indigo-500"><CameraIcon
                                            className='w-5'/> Image Here</div>}</div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreateTickets}>
                                            {loadingCreateTickets ? 'Loading' : 'Submit'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
