import {EventsLayout, InvitationsLayout, Notfound, TicketsLayout, UsersLayout} from '@/components';
import {LoginPage} from '@/components/pages/auth';
import DashboardPage from '@/components/pages/home/dashboard';
import DashBoardMain from '@/components/pages/home/dashboard/dashMain';
import CreateEvent from '@/components/pages/home/dashboard/events/create';
import {Navigate, useRoutes} from 'react-router-dom';
import {AccountProtector} from '@/core/protector';
import {AccountLayout} from '@/components/layouts/dashboard';
import Events from '@/components/pages/home/dashboard/events';
import DashUsers from '@/components/pages/home/dashboard/clients';
import CreateUser from '@/components/pages/home/dashboard/clients/create';
import CreateTicket from '@/components/pages/home/dashboard/tickets/create';
import CreateInvitation from '@/components/pages/home/dashboard/invitations/create';
import GenerateTickets from '@/components/pages/home/dashboard/tickets/generate';
import OnlineTickets from '@/components/pages/home/dashboard/tickets/online';
import OfflineTickets from '@/components/pages/home/dashboard/tickets/offline';
import PrintTickets from '@/components/pages/home/dashboard/tickets/print';
import ScanLayout from '@/components/layouts/scan';
import ScanTickets from '@/components/pages/home/dashboard/scan';
import PaymentPage from '@/components/pages/home/payment';
import Categorieslayout from '@/components/layouts/Categories';
import Categories from '@/components/pages/home/categories';

export default function AppRoutes() {
    return useRoutes([
        {path: '/login', element: <LoginPage/>},
        {
            path: '/', element: <DashboardPage/>, children: [
                {
                    index: true,
                    element: <AccountProtector element={<Navigate to="/dashboard"/>}/>
                },
                {
                    path: 'dashboard',
                    element: <AccountProtector element={<AccountLayout/>}/>,
                    children: [
                        {
                            index: true,
                            element: <DashBoardMain/>
                        },
                        {
                            path: 'events/*',
                            element: <EventsLayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <Events/>
                                },
                                {
                                    path: 'create',
                                    element: <CreateEvent/>
                                }
                            ]
                        },
                        {
                            path: 'catagories/*',
                            element: <Categorieslayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <Categories/>
                                },
                                {
                                    path: 'categories',
                                    element: <Categories/>
                                }
                            ]
                        },
                        {
                            path: 'users',
                            element: <UsersLayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <DashUsers/>
                                },
                                {
                                    path: 'create',
                                    element: <CreateUser/>
                                }
                            ]
                        },
                        {
                            path: 'ticket',
                            element: <TicketsLayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <GenerateTickets/>
                                },
                                {
                                    path: 'create',
                                    element: <CreateTicket/>
                                },
                                {
                                    path: 'online',
                                    element: <OnlineTickets/>
                                },
                                {
                                    path: 'offline',
                                    element: <OfflineTickets/>
                                },
                                {
                                    path: 'print',
                                    element: <PrintTickets/>
                                },
                            ]
                        },
                        {
                            path: 'invitations',
                            element: <InvitationsLayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <CreateInvitation/>
                                },
                                {
                                    path: 'create',
                                    element: <CreateInvitation/>
                                }
                            ]
                        },
                        {
                            path: 'scan',
                            element: <ScanLayout/>,
                            children: [
                                {
                                    index: true,
                                    element: <ScanTickets/>
                                },
                            ]
                        },
                    ]
                },
                {
                    path: '/'
                },
                {
                    path: '*',
                    element: <Notfound/>
                }
            ]
        },
        {path: 'payment/:slug', element: <PaymentPage/>},
        {path: '*', element: <Notfound/>}
    ]);
}
