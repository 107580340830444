import { useAppSelector } from "@/core/redux/store/types";
import { useGetAllStats } from "@/hooks/stats";
import {
	BanknotesIcon,
	CalendarIcon,
	ComputerDesktopIcon,
	CurrencyDollarIcon,
	TicketIcon,
	UsersIcon,
} from "@heroicons/react/24/outline";

export default function DashboardCards() {
	const { loadingStats, stats } = useGetAllStats();
	const { userAccount } = useAppSelector((state) => state).user;
	return (
		<div className="w-full flex justify-center border-gray-200 rounded-t-lg p-3">
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full">
				{loadingStats && (
					<>
						<DummyDashCard />
						<DummyDashCard />
						<DummyDashCard />
					</>
				)}
				{stats && !loadingStats ? (
					<>
						<DashCard
							color="bg-yellow-500"
							title="Offline Tickets"
							icon={<TicketIcon />} 
							value={stats.offlineTicket
								.reduce((accumulator, currentValue) => {
									return accumulator + currentValue.tickets;
								}, 0)}
						/>
						<DashCard
							color="bg-pink-800"
							title="Online Tickets"
							// value={stats.onlineTicket
							// 	.reduce((accumulator, currentValue) => {
							// 		return accumulator + currentValue.tickets;
							// 	}, 0)
							// 	.toString()}

							icon={<ComputerDesktopIcon />}
							value={stats.onlineTicket
								.reduce((accumulator, currentValue) => {
									return accumulator + currentValue.tickets;
								}, 0)
								.toString()}
						/>
						{userAccount?.role === "admin" ? (
							<>
								<DashCard
									color="bg-green-600"
									title="Successful Payments"
									value={stats.successPayment}
									icon={<BanknotesIcon />}
								/>
								<DashCard
									color="bg-red-600"
									title="Failed Payments"
									value={stats.failPayment}
									icon={<CurrencyDollarIcon />}
								/>
								<DashCard
									color="bg-purple-600"
									title="Users"
									value={stats.users}
									icon={<UsersIcon />}
								/>
							</>
						) : (
							<>
								{/* <DashCard
									color="bg-yellow-600"
									title="Total Invitations"
									value={stats.invitation.toString()}
									icon={<BuildingLibraryIcon />}
								/> */}
								<DashCard
									color="bg-secondary"
									title="Total Events"
									value={stats.events}
									icon={<CalendarIcon />}
								/>
							</>
						)}
					</>
				) : (
					""
				)}
			</div>
		</div>
	);
}

type DashCardProps = {
	icon: JSX.Element;
	color: String;
	title: String;
	value: any;
};

function DashCard(props: DashCardProps) {
	return (
		<div className="panel h-20 w-full p-0">
			<div className="flex p-5 ">
				<div className="shrink-0 text-primary  rounded-md w-11 h-11 flex justify-center items-center ">
					{props.icon}
				</div>
				<div className="ltr:ml-3 rtl:mr-3 font-semibold">
					<p className="text-xl dark:text-white-light">{props.value}</p>
					<h5 className="text-[#506690] text-xs">{props.title}</h5>
				</div>
			</div>
		</div>
	);
}

function DummyDashCard() {
	return (
		<div className="panel animate-pulse h-20 p-0">
			<div className="flex p-5">
				<div className="shrink-0 text-primary  rounded-md w-11 h-11 flex justify-center items-center ">
					
				</div>
				<div className="ltr:ml-3 rtl:mr-3 font-semibold">
					<p className="text-xl dark:text-white-light"></p>
					<p className="text-[#506690] text-xs"></p>
				</div>
			</div>
		</div>
	);
}
