import {LoaderIcon} from 'react-hot-toast';
import logo from '@/assets/images/ishema_logo_dark.png';
import {InputField} from '@/components/elements/input';
import Button, {ButtonLogin} from '@/components/elements/button';
import {useLoginUser} from '@/hooks/auth';
import {Form} from '@/components/elements/form';
import {z} from 'zod';
import {AuthLoginPayload} from '@/app/types/auth';
// @ts-ignore
import overlay from '@/assets/images/bg-login.svg';
import ill from '@/assets/images/m.png';

const schema = z.object({
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
});


export default function LoginPage() {
    const {loadingLogin, error, handleLogin} = useLoginUser();
    const defaultLoginPayload: AuthLoginPayload = {
        username: '',
        password: '',
    };
    return (
        <div className='dot-bg  w-full h-screen flex justify-start items-start'
        >
            <div
                className="h-screen border-gray-50 bg-white dark:bg-gray-950 flex flex-row w-full shadow-lg">
                <div
                    style={{
                        background: `url(${overlay})`,
                        backgroundSize: 'cover'
                    }}
                    className="w-1/2 bg-primary/90 flex-col hidden md:flex items-center  justify-center ">
                    <img src={ill} className='w-2/4 max-w-lg h-auto drop-shadow-2xl' alt=""/>
                    <h1 className={'text-3xl w-3/4 text-center font-bold text-white'}>
                        "Seamless access, limitless possibilities."
                    </h1>
                    <p className={'text-lg text-center w-3/4 text-white mt-3'}>
                        Welcome to ISHEMA ticket web portal,
                        where efficiency meets simplicity.
                        With just a few clicks, you can unlock a world of possibilities.
                    </p>
                </div>
                <Form
                    schema={schema}
                    onSubmit={handleLogin}
                    options={{defaultValues: defaultLoginPayload}}
                    className=" dark:border-transparent w-full md:w-1/2 flex flex-col justify-center pt-10 items-center border-gray-300/80 p-5">
                    {({register, formState}) => (
                        <>
                            <div className="flex flex-row w-full gap-2 md:w-8/12 items-center justify-center">
                                <div className="flex justify-center items-center mb-5">
                                    <img src={logo} className='w-14' alt="ishema"/>
                                </div>
                                <div className="w-full">
                                    <div className="w-full md:w-10/12 dark:text-secondary-light text-3xl font-bold">
                                        Welcome Back
                                    </div>
                                    <div
                                        className="w-full md:w-8/12 dark:text-secondary-light text-sm text-gray-700 mb-5">
                                        Fill In Your Credintials
                                    </div>
                                </div>
                            </div>
                            {error && (
                                <div className="px-3 text-sm text-red-500">
                                    {error}
                                </div>
                            )}
                            <div className="md:w-8/12 justify-center items-center flex gap-4 flex-col w-full">
                            <InputField
                                    isLogin={true}
                                    type='text'
                                    placeholder={'Enter Your Email'}
                                    // icon={<UserIcon className='w-5'/>}
                                    registration={register('username')}
                                    isLoading={loadingLogin}
                                    error={formState.errors.username}
                                />
                                <InputField
                                    isLogin={true}
                                    type='password'
                                    placeholder={'Enter Password'}
                                    // icon={<KeyIcon className='w-5'/>}
                                    registration={register('password')}
                                    isLoading={loadingLogin}
                                    error={formState.errors.password}
                                />
                                {/*<div className="flex flex-row justify-end items-center w-full"><Link to="/"*/}
                                {/*                                                                     className='text-sm text-primary font-semibold'>Forgot*/}
                                {/*    Password?</Link></div>*/}
                                <ButtonLogin className={'w-full'} onClick={() => {
                                }}>
                                    {loadingLogin ? <LoaderIcon color={'white'} className='my-[0.38rem] text-white bg-transparent'/> : 'Sign In'}
                                </ButtonLogin>
                            </div>
                            <div className="py-5 w-full justify-center items-center flex flex-row gap-2">
                                {/* <div className="w-1/3 border-b h-1"></div>or<div className="w-1/3 border-b h-1"></div> */}
                            </div>

                            {/*<div*/}
                            {/*    className="md:w-8/12 w-full dark:text-secondary-light p-4 flex flex-wrap justify-center gap-2">*/}
                            {/*    <p>Don't have an account?</p> <Link to="/"*/}
                            {/*                                        className=" text-primary font-semibold underline">Sign*/}
                            {/*    Up</Link>*/}
                            {/*</div>*/}
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
}
