
import { Modal } from '@/components/elements';
import Button from '@/components/elements/button';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { EventPayload } from '@/app/types/events';
import { Form } from '@/components/elements/form';
import { InputField, TextArea } from '@/components/elements/input';
import { useEditEvent } from '@/hooks/events';
import { CalendarDaysIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';
import {FormState, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import { z } from 'zod';
import { convertArrayToString } from '@/core/utils';
import Select from "react-select";

type EditEventModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function EditEventModal({ open, setOpen, title, selected, refresh }: EditEventModalProps) {
    return (
        <Modal size='lg' show={open}  setShow={setOpen} title={title} >
            <EditEvent id={selected?.id} defaultValues={selected} setRefresh={refresh} setOpen={setOpen} />
        </Modal>
    );
}

function EventDetails(props: {
	setTab: React.Dispatch<SetStateAction<number>>;
	register: UseFormRegister<EventPayload>;
    setValue: UseFormSetValue<EventPayload>
	formState: FormState<EventPayload>;
}) {
    const { register, formState, setValue } = props;

    return (
        <div>
            <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="text"
                        label="Event Title"
                        error={formState.errors.title}
                        registration={register('title')}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="text"
                        label="Event Organizer"
                        error={formState.errors.organiser}
                        registration={register('organiser')}
                    />
                    <InputField
                        type="text"
                        label="Phone Number"
                        error={formState.errors.phoneNumber}
                        registration={register('phoneNumber')}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <div className="flex flex-col md:flex-row gap-1 w-full">
                        <InputField
                            type="text"
                            label="Event Country"
                            error={formState.errors.country}
                            registration={register('country')}
                        />
                        <InputField
                            type="text"
                            label="Event Location"
                            error={formState.errors.location}
                            registration={register('location')}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <InputField
                        type="datetime-local"
                        label="Event Start Date"
                        error={formState.errors.date}
                        registration={register('date')}
                    />
                    <InputField
                        type="datetime-local"
                        label="Event End Date"
                        error={formState.errors.endDate}
                        registration={register('endDate')}
                    />
                </div>
                <InputField
                    type="text"
                    label="Currency"
                    error={formState.errors.currency}
                    registration={register('currency')}
                />
                <div className="flex flex-col">
                    <label>Channel</label>
                    <Select
                        onChange={(e) => setValue('channel', e?.value ?? 1)}

                        options={[{label: 'Ishema Tickets', value: 1}, {label: 'Ishusho Tickets', value: 2}]}/>
                </div>
                <div className="my-3 flex justify-end">
                    <div onClick={() => props.setTab(1)} className="btn-primary cursor-pointer btn">
                        Next
                    </div>
                </div>
            </div>
        </div>
    );
}

function EventDescription(props: {
    setTab: React.Dispatch<SetStateAction<number>>;
    register: UseFormRegister<EventPayload>;
    formState: FormState<EventPayload>;
    loading: boolean;
    image: string;
    setImage: React.Dispatch<SetStateAction<string>>;
}) {
    const {register, formState, loading, setImage} = props;
	
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    setImage(e.target.result as string);
                    register('image');
                }
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <div>
            <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="text"
                        label="Tags"
                        placeholder="Add Tag"
                        error={formState.errors.tags}
                        registration={register('tags')}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <InputField
                        type="file"
                        label="Event Image"
                        placeholder="Choose File"
                        onChange={handleImageChange}
                    />
                    <TextArea
                        label="Short Description"
                        error={formState.errors.description}
                        registration={register('description')}
                    />
                </div>
                <div className="my-3 flex justify-between">
                    <div onClick={() => props.setTab(0)} className="btn btn-primary">
						Previous
                    </div>
                    <Button
                        disabled={loading}
                        onClick={() => {}}
                        background="bg-primary"
                    >
                        {loading ? <LoaderIcon /> : 'Submit'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

const schema = z.object({
    title: z.string().min(1, { message: 'Title is required' }),
    phoneNumber: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must be inform of 2507........' }
    ),
    organiser: z.string().min(1, { message: 'Organizer is required' }),
    location: z.string().min(1, { message: 'Location is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    currency: z.string().min(1, { message: 'Currency is required' }),
    date: z.string().min(1, { message: 'Start Date is required' }),
    endDate: z.string().min(1, { message: 'End Date is required' }),
    tags: z.string().min(1, { message: 'Minimum of 1 tag required' }),
    channel: z.number()
});

function EditEvent(props : { defaultValues: EventPayload, id: string, setRefresh: React.Dispatch<SetStateAction<boolean>>, setOpen: React.Dispatch<SetStateAction<boolean>> }) {
    const { defaultValues, id, setOpen, setRefresh } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const { handleEditEvent, loadingEditEvent } = useEditEvent();
    const [image, setImage] = useState('');
	
    const tabs = [
		{
			icon: <CalendarDaysIcon className="icon-md w-3" />,
			title: "Details",
		},
		{
			icon: <NewspaperIcon className="icon-md w-3" />,
			title: "Description",
		},
	];
    return (
        <div className=" mx-2 shadow flex flex-col justify-center items-center">
            <div className="relative w-4/5 z-[1] mx-8">
				<div
					style={{
						width: `${`${Math.floor(
							(100 / tabs.length) * (tabIndex + 1) - 26
						).toString()}%`}`,
					}}
					className={` bg-primary h-1 absolute ltr:left-0 rtl:right-0 top-[20px] m-auto -z-[1] transition-[width]`}
				></div>
				<div className="grid grid-cols-2">
					{tabs.map((tab, index) => (
						<li
							key={index}
							className="mx-auto flex flex-col items-center justify-center"
						>
							<button
								type="button"
								className={`${
									(tabIndex > index || tabIndex === index)
										? "!border-primary !bg-primary text-white"
										: ""
								}
                            border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-10 h-10 rounded-full`}
								onClick={() => setTabIndex(1)}
							>
								{tab.icon}
							</button>
							<span
								className={`${
									tabIndex === index ? "text-primary " : ""
								}text-center block mt-2`}
							>
								{tab.title}
							</span>
						</li>
					))}
				</div>
			</div>
			<div className="p-4 w-full">
				<Form<EventPayload, typeof schema>
					onSubmit={(data) => handleEditEvent({ ...data, image: image }, id ).then(() => setOpen(false))}
                    options={{ defaultValues: { ...defaultValues, tags: getCommaSeparatedNames(defaultValues.tags as unknown as []) } }}
					schema={schema}
				>
					{({ register, formState, setValue }) => (
						<div className="">
							<div className={`${tabIndex === 0 ? "block" : "hidden"}`}>
								<EventDetails
									register={register}
                                    setValue={setValue}
									formState={formState}
									setTab={setTabIndex}
								/>
							</div>
							<div className={`${tabIndex === 1 ? "block" : "hidden"}`}>
								<EventDescription
									loading={loadingEditEvent}
									image={image}
									setImage={setImage}
									register={register}
									formState={formState}
									setTab={setTabIndex}
								/>
							</div>
							{/* <div className={`${tabIndex === 2 ? 'block' : 'hidden'}`}>
                                <EventTickets
                                    loading={loadingCreateEvent}
                                    image={image}
                                    setImage={setImage}
                                    register={register}
                                    formState={formState}
                                    setTab={setTabIndex}
                                />
                            </div> */}
						</div>
					)}
				</Form>
			</div>
        </div>
    );
}

function getCommaSeparatedNames(data: { name: string }[]): string {
    // Get the list of names from the dictionaries
    const names = data.map((item) => item.name);
    // Join the list of names with commas and return the string
    return names.join(",");
}