import PerfectScrollbar from "react-perfect-scrollbar";
import {Link, useLocation} from "react-router-dom";
import {toggleSidebar} from "@/components/../core/redux/store/themeConfigSlice";
import AnimateHeight from "react-animate-height";
import {useEffect, useState} from "react";
import IconCaretsDown from "@/components/Icon/IconCaretsDown";
import IconCaretDown from "@/components/Icon/IconCaretDown";

import {
    CalendarIcon,
    CameraIcon,
    EnvelopeOpenIcon,
    HomeIcon,
    TicketIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";
import {useAppDispatch, useAppSelector} from "@/core/redux/store/types";
import IconChartSquare from "@/components/Icon/IconChartSquare";
import logo from "@/assets/images/ishema_logo_light.png";

const Sidebar = () => {
    const [currentMenu, setCurrentMenu] = useState<string>("");
    const themeConfig = useAppSelector((state) => state.themeConfig);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? "" : value;
        });
    };

    const {userAccount} = useAppSelector((store) => store.user);

    const navigation = userAccount?.role === 'admin' ? [
        {
            name: "Dashboard",
            to: "dashboard",
            icon: HomeIcon,
            current: location.pathname === "/dashboard",
        },
        {
            name: "Categories ",
            to: "dashboard/catagories",
            icon: IconChartSquare,
            current: location.pathname === "/dashboard/catagories",
        },
        {
            name: "Invitations",
            to: "dashboard/invitations",
            icon: EnvelopeOpenIcon,
            current: location.pathname === "/dashboard/invitations",
        },
        {
            name: "Events",
            to: "dashboard/events",
            icon: CalendarIcon,
            current: location.pathname === "/dashboard/events",
        },
        {
            name: "Tickets",
            to: "dashboard/ticket",
            icon: TicketIcon,
            current: location.pathname === "/dashboard/ticket",
        },
        {
            name: "Scan Ticket",
            to: "dashboard/scan",
            icon: CameraIcon,
            current: location.pathname === "/dashboard/scan",
        },
        {
            name: "Users",
            to: "dashboard/users",
            icon: UsersIcon,
            current: location.pathname === "/dashboard/users",
        },
        // { name: 'Scan', to: 'dashboard/scan', icon: CameraIcon, current: location.pathname === '/dashboard/scan' },
    ] : userAccount?.role === 'organizer' ? [
        {
            name: "Dashboard",
            to: "dashboard",
            icon: HomeIcon,
            current: location.pathname === "/dashboard",
        },
        {
            name: "Invitations",
            to: "dashboard/invitations",
            icon: EnvelopeOpenIcon,
            current: location.pathname === "/dashboard/invitations",
        },
        {
            name: "Events",
            to: "dashboard/events",
            icon: CalendarIcon,
            current: location.pathname === "/dashboard/events",
        },
        {
            name: "Tickets",
            to: "dashboard/ticket",
            icon: TicketIcon,
            current: location.pathname === "/dashboard/ticket",
        },
        {
            name: "Scan Ticket",
            to: "dashboard/scan",
            icon: CameraIcon,
            current: location.pathname === "/dashboard/scan",
        },
        // { name: 'Scan', to: 'dashboard/scan', icon: CameraIcon, current: location.pathname === '/dashboard/scan' },
    ] : [        {
        name: "Events",
        to: "dashboard/events",
        icon: CalendarIcon,
        current: location.pathname === "/dashboard/events",
    },
        {
            name: "Tickets",
            to: "dashboard/ticket",
            icon: TicketIcon,
            current: location.pathname === "/dashboard/ticket",
        },] ;

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={"dark"}>
            <nav
                className={`sidebar capitalize fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <Link
                            to="/"
                            className="main-logo flex  flex-col items-center shrink-0"
                        >
                            <p className="ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light text-3xl">
                                <img src={logo} className={"w-14"} alt={"ISHEMA Tickets"}/>
                            </p>
                        </Link>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90"/>
                        </button>
                    </div>
                    <div className="h-10"></div>
                    <PerfectScrollbar className="h-[calc(100vh)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    {navigation.map((item, index) => {
                                        return (
                                            <li key={index} className="nav-item">
                                                <Link
                                                    to={item.to}
                                                    className={`group ${
                                                        item.current ? "active text-primary" : " "
                                                    }`}
                                                >
                                                    <div className="flex items-center">
                                                        <item.icon className="group-hover:!text-primary shrink-0"/>
                                                        <span
                                                            className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#a3a3a3] dark:group-hover:text-white-dark">
																{item.name}
															</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                    {/* {ItemDropDown(currentMenu, toggleMenu, {
                                        name: 'Admin Panel',
                                        Icon: UsersIcon,
                                        items: [
                                            { name: "User management", to: "user" },
                                            { name: "Group management", to: "group" },
                                            { name: "Role management", to: "role" },
                                            {
                                                name: "Creditor management",
                                                to: "creditor",
                                            },
                                        ]
                                    })} */}
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;

    type DropDownProps = {
        items: {
            name: string;
            to: string;
        }[];
        name: string;
        Icon: React.ForwardRefExoticComponent<
            Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
            title?: string | undefined;
            titleId?: string | undefined;
        } & React.RefAttributes<SVGSVGElement>
        >;
    };

    function ItemDropDown(
        currentMenu: string,
        toggleMenu: (value: string) => void,
        item: DropDownProps
    ) {
        return (
            <li className="menu nav-item">
                <button
                    type="button"
                    className={`${
                        currentMenu === item.name ? "" : ""
                    } nav-link group w-full`}
                    onClick={() => toggleMenu(item.name)}
                >
                    <div className="flex items-center">
                        <item.Icon className="group-hover:!text-primary shrink-0"/>
                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#a3a3a3] dark:group-hover:text-white-dark">
                            Admin Panel
                        </span>
                    </div>

                    <div
                        className={
                            currentMenu !== item.name ? "rtl:rotate-90 -rotate-90" : ""
                        }
                    >
                        <IconCaretDown/>
                    </div>
                </button>

                <AnimateHeight
                    duration={300}
                    height={currentMenu === item.name ? "auto" : 0}
                >
                    <ul className="sub-menu text-gray-500">
                        {item.items.map((item, index) => (
                            <li key={index}>
                                <Link to={item.to}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </AnimateHeight>
            </li>
        );
    }
