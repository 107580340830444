import { InvitationPayload } from "@/app/types/invitations";
import { PRIVATE_API } from "../axios";
import { AxiosErrorHandler, CustomError } from "@/core/utils";

export const create_invitation = async (data : InvitationPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/invitation', data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};