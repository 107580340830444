import { Charts, DashboardCards } from '../dashComponents';
import {useAppSelector} from "@/core/redux/store/types";

export default function DashBoardMain() {
    const { userAccount } = useAppSelector((state) => state.user);
    return (
        <div className='scrollbar-hidden slide-top overflow-y-scroll'>
            <DashboardCards />
            {<Charts/>}
        </div>
    );
}
