import Button from "@/components/elements/button";
import { FieldWrapper } from "@/components/elements/fieldwrapper";
import { InputField, SelectField } from "@/components/elements/input";
import { useGetAllEvents } from "@/hooks/events";
import { useGetAllTicketsType } from "@/hooks/ticket_type";
import { TicketIcon } from "@heroicons/react/24/outline";
import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useEffect, useState } from "react";
import {useGetAllBatchNames, useGetAllTicketsExported} from "@/hooks/ticket";
import { PrintTicketPayload, Ticket } from "@/app/types/tickets";
import { Form } from "@/components/elements/form";
import { z } from "zod";
import { LoaderIcon } from "react-hot-toast";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import Select from "react-select";

type Position = { 
	xRate: number;
	yRate: number;
};

const schema = z.object({
	from: z.string().min(1, "From is required"),
	to: z.string().min(1, "Quantity is required"),
	id: z.string().min(1, "Id is required"),
	size: z.string().min(1, "Size is required"),
	description: z.string().min(1, "Batch Name is required")
});

export default function PrintTickets() {
	const { handleGetEvents, events, loadingEvents } = useGetAllEvents();
	const getOfflineTickets = useGetAllTicketsExported();
	const { handleGetTickets, tickets, loadingTickets } = useGetAllTicketsType();
	const { loadingBatchNames, batchNames } = useGetAllBatchNames();
	const [selectedId, setSelectedId] = useState<string | null>(null);
	const [currentPosition, setCurrentPosition] = useState<Position>({
		xRate: 50,
		yRate: 50,
	});
	const [currentPositionId, setCurrentPositionId] = useState<Position>({
		xRate: 10,
		yRate: 10,
	});
	const onDrag = (e: DraggableEvent, data: DraggableData) => {
		setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
	};
	const onDragId = (e: DraggableEvent, data: DraggableData) => {
		setCurrentPositionId({ xRate: data.lastX, yRate: data.lastY });
		setIdRotation(idRotation + data.deltaX / 20);
	};
	const handleEventChange = (e: any) => {
		handleGetTickets();
		setSelectedId(e.target.value);
	};
	const [idRotation, setIdRotation] = useState(0);
	const [codeSize, setCodeSize] = useState(100);
	const [idSize, setIdSize] = useState(10);
	const initialDiv = React.useRef<HTMLImageElement | null>(null);
	const [overlayColor, setOverlayColor] = useState("#0000FF");
	const container = React.useRef<PDFExport>(null);
	const exportPDFWithComponent = () => {
		if (container.current) {
		  container.current.save();
		}
	  };
	const handleColorChange = (e: any) => {
		setOverlayColor(e.target.value);
	};
	const handleIdSize = (e: any) => {
		setIdSize(e.target.value);
	};
	const handleCodeSize = (e: any) => {
		setCodeSize(e.target.value);
	};
	const handleIdRotation = (e: any) => {
		setIdRotation(e.target.value);
	};
	useEffect(() => {
		handleGetEvents("", "");
		handleGetTickets();
	}, []);
	const [ticketHeight, setTicketHeight] = useState(100);
	useEffect(() => {
		if (initialDiv.current && getOfflineTickets.tickets) {
			setTicketHeight(165.4);
		}
	}, [getOfflineTickets.loadingTickets, getOfflineTickets.tickets]);
	return (
		<div className="h-screen mt-5">
			<div className="panel">
				<div className="px-4 flex overflow-scroll scrollbar-hidden flex-row gap-2 border-b dark:border-gray-700 border-gray-300 pb-5 items-center">
					<TicketIcon className="w-5" /> Print Tickets
				</div>
				<div className="px-4 py-3 pt-5 flex gap-2 flex-col">
					<Form<PrintTicketPayload, typeof schema>
						onSubmit={getOfflineTickets.handleGetTickets}
					>
						{({ register, formState, watch,setValue }) => (
							<div className="flex-col mb-5 flex gap-2">
								<div className="grid grid-cols-2 gap-2 ">
									<Select options={batchNames?.uniqueDescriptions.map((item) => {
										return {
											label: item,
											value: item,
										}
									})} onChange={(e) => setValue('description', e?.value ?? '')}  placeholder={'Select Batch Name'} />
									{/*<SelectField*/}
									{/*	placeholder="Select Event"*/}
									{/*	isLoading={loadingEvents}*/}
									{/*	options={FormatSelectOptions(events?.list || [])}*/}
									{/*	onChange={handleEventChange}*/}
									{/*/>*/}
									{/*<SelectField*/}
									{/*	placeholder="Select Ticket"*/}
									{/*	isLoading={loadingTickets || tickets === null}*/}
									{/*	options={FormatTicketTypeSelectOptions(*/}
									{/*		tickets?.list.filter(*/}
									{/*			(value) => value.event.id === selectedId*/}
									{/*		) || []*/}
									{/*	)}*/}
									{/*	error={formState.errors.id}*/}
									{/*	registration={register("id")}*/}
									{/*/>*/}
									<InputField
										type="number"
										error={formState.errors.from}
										registration={register("from")}
										placeholder="From"
									/>
									<InputField
										type="number"
										error={formState.errors.to}
										registration={register("to")}
										placeholder="To"
									/>
									<InputField
										type="number"
										error={formState.errors.size}
										registration={register("size")}
										placeholder="Quantity"
									/>
								</div>
								<div className="">
									<Button
										disabled={getOfflineTickets.loadingTickets}
										title="Generate tickets"
									>
										Submit
									</Button>
								</div>
							</div>
						)}
					</Form>
				</div>
				{getOfflineTickets.loadingTickets ? (
					<div className="w-full py-3 justify-center flex">
						<LoaderIcon style={{ width: '30px', height: '30px' }} />
					</div>
				) : (
					getOfflineTickets.tickets ? (
						getOfflineTickets.tickets.list.length === 0 ? <div className="w-full py-3 justify-center flex">No Tickets Found.</div> :
						<div className="px-4 flex flex-col justify-center items-center">
							<div className="flex items-end flex-col gap-1 justify-end text-sm">
								<div className="flex w-full flex-row gap-1">
									<div className="flex flex-col items-start justify-start gap-1">
										<p>QR Code Position</p>
										<div className="py-2 w-full flex flex-row gap-2">
											<InputField
												type="number"
												label="Size"
												onChange={handleCodeSize}
											/>
										</div>
									</div>
									<div className="flex flex-col items-start justify-start gap-1">
										<p>Id Position</p>
										<div className="py-2 flex flex-row  gap-2">
											<InputField
												type="number"
												label="Font Size"
												onChange={handleIdSize}
											/>
											<InputField
												type="number"
												label="Rotation"
												onChange={handleIdRotation}
											/>
											<FieldWrapper label="Select Color">
												<div className="flex w-full flex-row items-center justify-center">
													<input
														type="color"
														className="text-black bg-transparent px-1 font-normal w-full focus:outline-none focus:ring-0"
														onChange={handleColorChange}
													/>
												</div>
											</FieldWrapper>
										</div>
									</div>
								</div>
								<div className=" py-2 items-end flex flex-row gap-2">
									<Button
										title="Exp	ort PDF"
										className=""
										onClick={exportPDFWithComponent}
									>
										Export
									</Button>
								</div>
							</div>
							<div className="">
								<PDFExport paperSize={"A4"} ref={container}>
									<div className="w-[595px] relative">
										{loadingEvents ? (
											<div className="flex justify-center my-10 items-center"><LoaderIcon style={{ height: '30px', width: '30px' }} /></div>
										) : (
											<div className={` w-full grid ${getOfflineTickets.tickets.list[0].pricing.design === 'large' ? 'grid-cols-1' : 'grid-cols-2'} gap-[3px]`}>
												{getOfflineTickets.tickets?.list?.map((item, index) => (
													<div className="" key={index}>
														<Draggable
															position={{
																x: currentPosition.xRate,
																y: currentPosition.yRate,
															}}
															onDrag={onDrag}
														>
															<div
																style={{
																	width: `${codeSize}px`,
																}}
																className={`absolute cursor-move bg-black`}
															>
																<img
																	src={`https://quickchart.io/qr?text=${item.ticket_id}`}
																	alt={`qr code`}
																	className="w-full"
																/>
															</div>
														</Draggable>
														<Draggable
															position={{
																x: currentPositionId.xRate,
																y: currentPositionId.yRate,
															}}
															
															onDrag={onDragId}
														>
															<div
																style={{
																	width: `auto`,
																	fontSize: `${idSize}px`,
																	backgroundColor: overlayColor,
																	transform: `rotate(${idRotation}deg)`
																}}
																className={` px-[5px] cursor-move w-auto text-center absolute text-white`}
															>
																#{item.id}
															</div>
														</Draggable>
														<div className="">
															<TicketView my_ref={initialDiv} ticket={item} />
														</div>
													</div>
												))}
											</div>
										)}
									</div>
								</PDFExport>
							</div>
						</div>
					) : ''
				)}
			</div>
		</div>
	);
}

function TicketView(props: {
	ticket: Ticket;
	my_ref: React.MutableRefObject<HTMLImageElement | null>;
}) {
	return (
		<div className="w-full">
			<img
				src={props.ticket.pricing.image}
				className="h-[165.4px] w-[595px]"
				ref={props.my_ref}
				draggable="false"
				onDrag={(e: any) => e.preventDefault()}
				alt=""
			/>
		</div>
	);
}
