import React, {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import IconPlus from "@/components/Icon/IconPlus";

import {z} from "zod";
import {useNavigate} from "react-router-dom";
import {Form} from "@/components/elements/form";
import {InputField} from "@/components/elements/input";
import Button from "@/components/elements/button";
import {useCreatecategories} from "@/hooks/Categories";

const schema = z.object({
    name: z.string().min(1, "Name is  required"),
    icon: z.string().min(1, {message: "Icon is required"})
});

export type RegisterInput = z.TypeOf<typeof schema>;

export const CreateCategory = ({refresh}: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const {loadingCreatecategories, handleCreatecategories} = useCreatecategories();
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary btn-sm m-1"
                >
                    <IconPlus className="w-5 h-5 ltr:mr-2 rtl:ml-2"/>
                    Add category
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0"/>
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div
                                        className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Add category
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (data) => {

                                                await handleCreatecategories(data);
                                                refresh((prev: any) => !prev);
                                                setModal1(false);
                                            }}
                                            className="mt-3  "
                                        >
                                            {({register, formState}) => (
                                                <>

                                                    <InputField
                                                        type="text"
                                                        label="Names"
                                                        placeholder="Ex. music"
                                                        error={formState.errors.name}
                                                        registration={register("name")}
                                                    />
                                                    <InputField
                                                        type="text"
                                                        label="Icon"
                                                        placeholder="Ex. fa fa-icon"
                                                        error={formState.errors.icon}
                                                        registration={register("icon")}
                                                    />
                                                    <div className="flex justify-end items-center mt-2">
                                                        <Button disabled={loadingCreatecategories}>Submit</Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
