import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function TicketsLayout() {
	const location = useLocation();
	const link = [
		{
			to: "/dashboard/ticket",
			title: "Generate",
			active: location.pathname === "/dashboard/ticket",
		},
		{
			to: "/dashboard/ticket/online",
			title: "Online",
			active: location.pathname === "/dashboard/ticket/online",
		},
		{
			to: "/dashboard/ticket/offline",
			title: "Offline",
			active: location.pathname === "/dashboard/ticket/offline",
		},
		{
			to: "/dashboard/ticket/print",
			title: "Print",
			active: location.pathname === "/dashboard/ticket/print",
		},
	];
	return (
		<>
			<Tab.Group>
				<Tab.List className="mt-3 flex flex-wrap">
                    {link.map((tab, index) => (
                        <Tab as={Fragment}>
                            {() => (
                                <Link
                                    to={tab.to}
                                    className={`${
                                        tab.active
                                            ? "border-b !border-secondary text-secondary !outline-none"
                                            : ""
                                    } -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                                >
                                    
                                    {tab.title}
                                </Link>
                            )}
                        </Tab>
                    ))}
				</Tab.List>
			</Tab.Group>
			<div>
				<Outlet />
			</div>
		</>
	);
}

function TabLink(props: { title: string; to: string; active: boolean }) {
	return (
		<Link
			to={props.to}
			className={`py-2 px-3 ${
				props.active ? "bg-primary text-gray-200" : "bg-gray-200"
			} hover:bg-indigo-500 hover:text-gray-200`}
		>
			{props.title}
		</Link>
	);
}
