import Button from '@/components/elements/button'
import {Form} from '@/components/elements/form'
import {InputField} from '@/components/elements/input'
import {useScanTicket} from '@/hooks/ticket_type'
import {LoaderIcon} from 'react-hot-toast';
import {z} from 'zod';

const schema = z.object({
    id: z.string().min(1, 'Ticket ID is required'),
});

export default function ScanTickets() {
    const {handleScanTicket, loadingScan, scanned} = useScanTicket();
    return (
        <div className='h-screen mt-5'>
            <div className="w-12/12 panel">
                <Form<{ id: string }, typeof schema>
                    schema={schema}
                    onSubmit={(data, e) => handleScanTicket(data.id).then(() => e?.target.reset())}>
                    {({register, formState}) => (
                        <div className="px-3 flex flex-col gap-2">
                            <InputField label='Enter Ticket ID' isLoading={loadingScan} registration={register('id')}
                                        error={formState.errors.id} type='text' placeholder='Id'/>
                            <Button disabled={loadingScan}>{loadingScan ?
                                <div className="py-[0.36rem]"><LoaderIcon/></div> : 'Scan'}</Button>
                        </div>
                    )}
                </Form>
                {scanned && <div className="py-3 flex justify-center items-center px-3">
                    {scanned}
                </div>}
            </div>
        </div>
    )
}
