import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PRIVATE_API } from '../axios';
import { EventPayload, EventType } from '@/app/types/events';
import { PaginationType } from '@/app/types/shared';
import { convertStringToArray, queryString } from '@/core/utils';
import {StatsType} from "@/app/types/stats";

export const get_events = async (query? : string): Promise<PaginationType<EventType>> => {
    try {
        const request = await PRIVATE_API.get(`/event${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_stats = async (): Promise<PaginationType<EventType>> => {
    try {
        const request = await PRIVATE_API.get(`/event/stats`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_event_stats = async (id: string): Promise<StatsType> => {
    try {
        const request = await PRIVATE_API.get(`/ticket/stats/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const get_events_by_slug = async (slug : string): Promise<EventType> => {
    try {
        const request = await PRIVATE_API.get(`/event/${slug}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_event = async (payload : EventPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/event', { ...payload, tags: convertStringToArray(payload.tags || '')  });
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_event = async (payload : EventPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/event/${id}`, { ...payload, tags: convertStringToArray(payload.tags || '')  });
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_event = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/event/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const update_status_event = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(`/event/updateStatus/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};