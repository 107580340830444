
export default function ErrorLayout() {
    return (
        <div className="dot-bg absolute pt-20 z-[999999] flex justify-center items-start bg-gray-300 text-black left-0 right-0 bottom-0 top-0">
            <div className="p-10 w-11/12 h-80 max-w-[800px] shadow-md flex flex-col gap-4 justify-center items-center border border-gray-400/70 rounded-md">
                <p className="text-[40px]">500</p>
                <div className="flex flex-col gap-4">
                    <p>An Unexpected Error Occurred</p>
                    <button onClick={() => window.location.href = '/'}>Return</button>
                </div>
            </div>
        </div>
    );
}
