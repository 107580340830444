import {  Suspense, useEffect, useState } from 'react';
import { toggleSidebar } from '../../../../core/redux/store/themeConfigSlice';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/core/redux/store/types';
import Sidebar from '@/components/elements/sidenav';
import Header from '@/components/elements/header';
import Footer from '@/components/elements/Footer';
import Portals from '@/components/elements/Portals';
import { LoaderIcon } from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function AdminLayout() {
    const themeConfig = useAppSelector((state) => state.themeConfig);
    const dispatch = useAppDispatch();

    const [showLoader, setShowLoader] = useState(true);
    const [showTopButton, setShowTopButton] = useState(false);

    const goToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const onScrollHandler = () => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler);

        const screenLoader = document.getElementsByClassName('screen_loader');
        if (screenLoader?.length) {
            screenLoader[0].classList.add('animate__fadeOut');
            setTimeout(() => {
                setShowLoader(false);
            }, 200);
        }

        return () => {
            window.removeEventListener('onscroll', onScrollHandler);
        };
    }, []);

    return (
        <div className="relative">
          
            <div className={`${(!themeConfig.sidebar && 'hidden') || ''} fixed inset-0 bg-[black]/60 z-50 lg:hidden`} onClick={() => dispatch(toggleSidebar())}></div>
           
            {showLoader && (
                <div className="screen_loader fixed inset-0 bg-[#fafafa] dark:bg-[#060818] z-[999] grid place-content-center animate__animated">
                    <ArrowPathIcon className='w-6 animate-spin text-primary' />
                </div>
            )}
            <div className="fixed bottom-6 ltr:right-6 rtl:left-6 z-50">
                {showTopButton && (
                    <button type="button" className="btn btn-outline-primary rounded-full p-2 animate-pulse bg-[#fafafa] dark:bg-[#060818] dark:hover:bg-primary" onClick={goToTop}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7l4-4m0 0l4 4m-4-4v18" />
                        </svg>
                    </button>
                )}
            </div>
            <div className={`${themeConfig.navbar} main-container text-black dark:text-white-dark min-h-screen`}>
              
                <Sidebar />
               

                <div className="main-content flex flex-col min-h-screen">
                   
                    <Header />
                   
                    
                    {/* BEGIN CONTENT AREA */}
                    <Suspense>
                        <div className={`${themeConfig.animation} overflow-y-scroll mb-4 scrollbar-hidden p-6 animate__animated`}>
                            <Outlet />
                        </div>
                    </Suspense>
                 
                    <Footer />
                   
                    <Portals />
                </div>
            </div>
        </div>
    );
};
