import {useState} from "react";
import {Link} from "react-router-dom";
import {toggleSidebar, toggleTheme,} from "@/core/redux/store/themeConfigSlice";
import Dropdown from "../Dropdown";
import IconMenu from "../../Icon/IconMenu";
import IconSearch from "../../Icon/IconSearch";
import IconXCircle from "../../Icon/IconXCircle";
import IconSun from "../../Icon/IconSun";
import IconMoon from "../../Icon/IconMoon";
import IconLaptop from "../../Icon/IconLaptop";
import IconUser from "../../Icon/IconUser";
import IconLogout from "../../Icon/IconLogout";
import logo from "@/assets/images/ishema_logo_dark.png";
import profile from "@/assets/images/person.png";
import {useAppDispatch, useAppSelector} from "@/core/redux/store/types";
import {useLogout} from "@/hooks/auth";

export default function Header() {
    const isRtl =
        useAppSelector((state) => state.themeConfig.rtlClass) === "rtl"
            ? true
            : false;
    const {userAccount} = useAppSelector((store) => store.user);

    const {handleLogout} = useLogout();

    const themeConfig = useAppSelector((state) => state.themeConfig);
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState(false);

    return (
        <header
            className={`z-40 ${
                themeConfig.semidark && themeConfig.menu === "horizontal" ? "dark" : ""
            }`}
        >
            <div className="shadow-sm">
                <div className="relative bg-white flex w-full items-center px-5 py-2.5 dark:bg-black">
                    <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
                        <Link to="/" className="main-logo flex items-center shrink-0">
                            <img
                                className="w-10 ltr:-ml-1 rtl:-mr-1 inline"
                                src={logo}
                                alt="logo"
                            />
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                            onClick={() => {
                                dispatch(toggleSidebar());
                            }}
                        >
                            <IconMenu className="w-5 h-5"/>
                        </button>
                    </div>

                    <div
                        className="sm:flex-1 ltr:sm:ml-0 ltr:ml-auto sm:rtl:mr-0 rtl:mr-auto flex items-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">
                        <div className="sm:ltr:mr-auto sm:rtl:ml-auto">
                            <form
                                className={`${
                                    search && "!block"
                                } sm:relative absolute inset-x-0 sm:top-0 top-1/2 sm:translate-y-0 -translate-y-1/2 sm:mx-0 mx-4 z-10 sm:block hidden`}
                                onSubmit={() => setSearch(false)}
                            >
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="form-input ltr:pl-9 rtl:pr-9 ltr:sm:pr-4 rtl:sm:pl-4 ltr:pr-9 rtl:pl-9 peer sm:bg-transparent bg-gray-100 placeholder:tracking-widest"
                                        placeholder="Search..."
                                    />
                                    <button
                                        type="button"
                                        className="absolute w-9 h-9 inset-0 ltr:right-auto rtl:left-auto appearance-none peer-focus:text-primary"
                                    >
                                        <IconSearch className="mx-auto"/>
                                    </button>
                                    <button
                                        type="button"
                                        className="hover:opacity-80 sm:hidden block absolute top-1/2 -translate-y-1/2 ltr:right-2 rtl:left-2"
                                        onClick={() => setSearch(false)}
                                    >
                                        <IconXCircle/>
                                    </button>
                                </div>
                            </form>
                            <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="search_btn sm:hidden p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                            >
                                <IconSearch className="w-4.5 h-4.5 mx-auto dark:text-[#d0d2d6]"/>
                            </button>
                        </div>
                        <div>
                            {themeConfig.theme === "light" ? (
                                <button
                                    className={`${
                                        themeConfig.theme === "light" &&
                                        "flex items-center p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme("dark"));
                                    }}
                                >
                                    <IconSun/>
                                </button>
                            ) : (
                                ""
                            )}
                            {themeConfig.theme === "dark" && (
                                <button
                                    className={`${
                                        themeConfig.theme === "dark" &&
                                        "flex items-center p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme("system"));
                                    }}
                                >
                                    <IconMoon/>
                                </button>
                            )}
                            {themeConfig.theme === "system" && (
                                <button
                                    className={`${
                                        themeConfig.theme === "system" &&
                                        "flex items-center p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme("light"));
                                    }}
                                >
                                    <IconLaptop/>
                                </button>
                            )}
                        </div>
                        <div className="dropdown shrink-0 flex">
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                                btnClassName="relative group block"
                                button={
                                    <div className="rounded-full border dark:border-slate-500 p-[1px]">
                                        <img
                                            className="rounded-full w-9 h-9  object-cover dark:bg-secondary-light"
                                            src={profile}
                                            alt="userProfile"
                                        />
                                    </div>
                                }
                            >
                                <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                                    <li>
                                        <div className="flex items-center p-2">
                                            <div className="w-2/5 flex justify-center items-center">
                                                <div className="rounded-full  border dark:border-slate-500 p-[1px]">
                                                    <img
                                                        className="rounded-full w-9 h-9  object-cover dark:bg-secondary-light"
                                                        src={profile}
                                                        alt="userProfile"
                                                    />
                                                </div>
                                            </div>
                                            <div className="ltr:pl-1 rtl:pr-4 truncate">
                                                <h4 className="text-base">
                                                    {userAccount?.username}
                                                    <span
                                                        className="text-xs bg-success-light rounded text-success px-1 ltr:ml-2 rtl:ml-2 capitalize">
														{userAccount?.role}
													</span>
                                                </h4>
                                                <button
                                                    type="button"
                                                    className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                                                >
                                                    {userAccount?.email}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to="/users/profile" className="dark:hover:text-white">*/}
                                    {/*        <IconUser className="w-4.5 h-4.5 ltr:mr-2 rtl:ml-2 shrink-0"/>*/}
                                    {/*        Profile*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li className="border-t border-white-light dark:border-white-light/10">
                                        <button
                                            onClick={() => handleLogout()}
                                            className="text-danger !py-3"
                                        >
                                            <IconLogout className="w-4.5 h-4.5 ltr:mr-2 rtl:ml-2 rotate-90 shrink-0"/>
                                            Sign Out
                                        </button>
                                    </li>
                                </ul>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
