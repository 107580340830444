import { TicketsFilterPayload } from "@/app/types/tickets_types";
import { Modal } from "@/components/elements";
import Button from "@/components/elements/button";
import { Form } from "@/components/elements/form";
import { InputField, SelectField } from "@/components/elements/input";
import DataTable, { TableColumn } from "@/components/elements/tables/Datatable";
import {
	FormatSelectOptions,
	FormatTicketTypeSelectOptions,
	format,
} from "@/core/utils";
import { useGetAllEvents } from "@/hooks/events";
import { useGetAllTickets } from "@/hooks/ticket";
import {
	DocumentIcon,
	FunnelIcon,
	MagnifyingGlassIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import DeleteTicketModal from "../modals/delete_modal";
import EditTicketModal from "../modals/edit_modal";
import SoldOutTicketModal from "../modals/sold_modal";
import { Ticket } from "@/app/types/tickets";
import { useGetAllTicketsType } from "@/hooks/ticket_type";
import DataTableV2, {
	TableColumnV2,
} from "@/components/elements/tables/Datatable/DataTable";

export default function OfflineTickets() {
	const { loadingTickets, handleGetTickets, tickets } = useGetAllTickets();
	const [openDelete, setOpenDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openSold, setOpenSold] = useState(false);
	const [selected, setSelected] = useState<Ticket | null>(null);
	const [refresh, setRefresh] = useState(false);
	const [filters, setFilters] = useState<TicketsFilterPayload>({
		from: "",
		to: "",
		eventId: "",
		pricingId: "",
	});
	const location = useLocation();
	const query = location.search;
	useEffect(() => {
		handleGetTickets();
	}, [query, refresh]);
	const columns: TableColumnV2<Ticket>[] = [
		{
			title: "Event",
			accessor: "event",
			render: (row) => <p>{row.event?.title}</p>,
		},
		{
			title: "Type",
			accessor: "type",
			render: (row) => <p>{row.pricing.type}</p>,
		},
		{
			title: "Pricing",
			accessor: "pricing",
			render: (row) => <p>{row.pricing.price}</p>,
		},
		// {
		// 	title: "Status",

		// accessor: '',// 	render: (row) => (
		// 		<div className="">
		// 			<div
		// 				className={`px-1 flex w-16 rounded text-center justify-center items-center py-1 ${
		// 					row.status === "active"
		// 						? "bg-green-600 text-gray-200"
		// 						: "bg-red-700 text-gray-200"
		// 				}`}
		// 			>
		// 				{row.status}
		// 			</div>
		// 		</div>
		// 	),
		// },
		{
			title: "Sold Type",
			accessor: "sold_type",
			render: (row) => <p>{row.sold_type}</p>,
		},
		{
			title: "Created At",
			accessor: "created_at",
			render: (row) => <p>{format.humanDate(row.created_at)}</p>,
		},
		{
			title: "Actions",
			accessor: "actions",
			render: (row) => (
				<div className="flex flex-wrap items-center gap-2 justify-center">
					{/* <button title="Sold out" className="p-1 rounded  bg-orange-500 text-white">
						<TicketIcon
							onClick={() => {
								setSelected(row);
								setOpenSold(true);
							}}
							className="w-4"
						/>
					</button> */}
					<button className="p-1 rounded  bg-primary text-white">
						<PencilIcon
							onClick={() => {
								setSelected(row);
								setOpenEdit(true);
							}}
							className="w-4"
						/>
					</button>
					<button className="p-1 rounded  bg-red-700 text-white">
						<TrashIcon
							onClick={() => {
								setSelected(row);
								setOpenDelete(true);
							}}
							className="w-4"
						/>
					</button>
				</div>
			),
		},
	];
	return (
		<div className="h-screen mt-5">
			<div className="">
				<DataTableV2
					data={tickets?.list ?? []}
					currentPage={tickets?.currentPage ?? 0}
					lastPage={tickets?.lastPage ?? 0}
					total={tickets?.total ?? 0}
					isLoading={loadingTickets}
					previousPage={tickets?.previousPage ?? 0}
					columns={columns}
					nextPage={tickets?.nextPage ?? 0}
					header={
						<div className="flex text-sm justify-end flex-wrap items-center gap-2 py-3">
							<Filter
								filters={filters}
								onFilter={(payload: TicketsFilterPayload) => {
									setFilters(payload);
								}}
							/>
						</div>
					}
				/>
				<DeleteTicketModal
					refresh={setRefresh}
					selected={selected}
					open={openDelete}
					setOpen={setOpenDelete}
					title="Delete Ticket type"
				/>
				<EditTicketModal
					refresh={setRefresh}
					selected={selected}
					open={openEdit}
					setOpen={setOpenEdit}
					title="Edit Ticket Type"
				/>
				<SoldOutTicketModal
					refresh={setRefresh}
					selected={selected}
					open={openSold}
					setOpen={setOpenSold}
					title="Mark as sold out"
				/>
			</div>
		</div>
	);
}

type FilterProps = {
	filters: TicketsFilterPayload;
	onFilter: (...args: any) => void;
};

function Filter(props: FilterProps) {
	const { handleGetEvents, events } = useGetAllEvents();
	const { handleGetTickets, tickets } = useGetAllTicketsType();
	const { onFilter, filters } = props;
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const schema = z.object({
		from: z.string().optional(),
		to: z.string().optional(),
		eventId: z.string().optional(),
		pricingId: z.string().optional(),
	});
	const defaultFilters: TicketsFilterPayload = {
		from: "",
		to: "",
		eventId: "",
		pricingId: "",
	};
	const handleOnSubmit = (payload: any) => {
		const searchParams = new URLSearchParams(location.search);

		const { from, to, eventId, pricingId } = payload;

		if (from) searchParams.set("from", from);
		if (to) searchParams.set("to", to);
		if (eventId) searchParams.set("eventId", eventId);
		if (pricingId) searchParams.set("pricingId", pricingId);

		const newSearch = searchParams.toString();
		onFilter(payload);
		navigate(`${location.pathname}?${newSearch}`);

		setOpen(false);
	};
	const resetFilter = () => {
		onFilter(defaultFilters);
		navigate(`${location.pathname}`);
		setOpen(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		handleGetEvents("", "");
		handleGetTickets();
	}, []);
	return (
		<>
			<div className="w-full justify-end flex flex-wrap">
				<div className="flex flex-row gap-3">
					<Button onClick={() => setOpen(true)}>
						<FunnelIcon className="w-4" /> Filter
					</Button>
				</div>
			</div>
			<Modal show={open} setShow={setOpen} title={"Filter"}>
				<Form<TicketsFilterPayload, typeof schema>
					onSubmit={handleOnSubmit}
					schema={schema}
					options={{ defaultValues: filters }}
				>
					{({ register, formState }) => (
						<div className="px-3">
							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<InputField
									type="date"
									error={formState.errors.from}
									registration={register("from")}
									className="h-12"
									label="Start Date"
								/>

								<InputField
									type="date"
									error={formState.errors.to}
									registration={register("to")}
									className="h-12"
									label="End Date"
								/>
							</div>
							<div className="flex flex-col md:flex-row gap-2">
								<SelectField
									label="Event"
									options={FormatSelectOptions(events?.list || [])}
									placeholder="Select Event"
									error={formState.errors.eventId}
									registration={register("eventId")}
								/>
							</div>
							<div className="flex flex-col md:flex-row gap-2">
								<SelectField
									label="Ticket Type"
									options={FormatTicketTypeSelectOptions(tickets?.list || [])}
									placeholder="Select Ticket Type"
									error={formState.errors.pricingId}
									registration={register("pricingId")}
								/>
							</div>
							<div className="py-4 flex flex-row justify-between">
								<Button onClick={resetFilter} background="bg-gray-500">
									Reset
								</Button>
								<Button>Filter</Button>
							</div>
						</div>
					)}
				</Form>
			</Modal>
		</>
	);
}
