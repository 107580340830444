import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function EventsLayout() {
    const location = useLocation();
    const link = [
        {
            to: '/dashboard/events',
            title: 'Events',
            active: location.pathname === '/dashboard/events',
        },
        {
            to: '/dashboard/events/create',
            title: 'Create',
            active: location.pathname === '/dashboard/events/create',
        },
    ];
    return (
        <>
        <Tab.Group>
            <Tab.List className="mt-3 flex flex-wrap">
                {link.map((tab, index) => (
                    <Tab as={Fragment}>
                        {() => (
                            <Link
                                to={tab.to}
                                className={`${
                                    tab.active
                                        ? "border-b !border-secondary text-secondary !outline-none"
                                        : ""
                                } -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                            >
                                
                                {tab.title}
                            </Link>
                        )}
                    </Tab>
                ))}
            </Tab.List>
        </Tab.Group>
        <div className="mt-5">
            <Outlet />
        </div>
    </>
    );
}
