/* eslint-disable react-hooks/exhaustive-deps */
import { EventFilterPayLoad, EventType } from "@/app/types/events";
import { Modal } from "@/components/elements";
import Button from "@/components/elements/button";
import { Form } from "@/components/elements/form";
import { InputField } from "@/components/elements/input";
import { useGetAllEvents } from "@/hooks/events";
import {
	DocumentIcon,
	EyeIcon,
	FunnelIcon,
	MagnifyingGlassIcon,
	PencilIcon,
	TrashIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import DeleteEventModal from "./modals/delete_modal";
import PrintModal from "./modals/print_modal";
import { PaginationType } from "@/app/types/shared";
import EditEventModal from "./modals/edit_modal";
import StatusEventModal from "./modals/update_status_modal";
import EventDetailsModal from "./modals/event_details_modal";
import DataTableV2, {
	TableColumnV2,
} from "@/components/elements/tables/Datatable/DataTable";

export default function Events() {
	const { handleGetEvents, loadingEvents, events } = useGetAllEvents();
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState<EventType | null>(null);
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(false);
	const [openDetails, setOpenDetails] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openStatus, setOpenStatus] = useState(false);
	const [filters, setFilters] = useState<EventFilterPayLoad>({
		from: "",
		to: "",
		channel: "",
	});
	const location = useLocation();
	const query = location.search;
	useEffect(() => {
		handleGetEvents(query, search);
	}, [query, search, loading, open, openEdit]);

	const columns: TableColumnV2<EventType>[] = [
		{
			title: "Title",
			accessor: "title",
			render: (row) => <p>{row.title}</p>,
		},
		{
			title: "Organiser",
			accessor: "organiser",
			render: (row) => <p>{row.organiser}</p>,
		},
		{
			title: "Country",
			accessor: "country",
			render: (row) => <p>{row.country}</p>,
		},
		{
			title: "Location",
			accessor: "location",
			render: (row) => <p>{row.location}</p>,
		},
		{
			title: "Description",
			accessor: "description",
			render: (row) => (
				<p className="max-w-[200px] overflow-scroll scrollbar-hidden">
					{row.description}{" "}
				</p>
			),
		},
		{
			title: "Currency",
			accessor: "currency",
			render: (row) => <p>{row.currency}</p>,
		},
		{
			title: "Status",
			accessor: "status",
			render: (row) => <p>{row.status}</p>,
		},
		{
			title: "Actions",
			accessor: "actions",
			render: (row) => (
				<div className="flex flex-row items-center gap-2 justify-center">
					<button className="p-1 rounded  bg-orange-500 text-white">
						<XMarkIcon
							onClick={() => {
								setSelected(row);
								setOpenStatus(true);
							}}
							className="w-4"
						/>
					</button>
					<button className="p-1 rounded  bg-primary text-white">
						<PencilIcon
							onClick={() => {
								setSelected(row);
								setOpenEdit(true);
							}}
							className="w-4"
						/>
					</button>
					<button title="Delete" className="p-1 rounded  bg-red-700 text-white">
						<TrashIcon
							onClick={() => {
								setSelected(row);
								setOpen(true);
							}}
							className="w-4"
						/>
					</button>
					<button
						title="Event Tickets"
						className="p-1 rounded  bg-blue-700 text-white"
					>
						<EyeIcon
							onClick={() => {
								setSelected(row);
								setOpenDetails(true);
							}}
							className="w-4"
						/>
					</button>
				</div>
			),
		},
	];
	return (
		<div className="">
			<DataTableV2
				data={events?.list ?? []}
				currentPage={events?.currentPage ?? 0}
				lastPage={events?.lastPage ?? 0}
				total={events?.total ?? 0}
				isLoading={loadingEvents}
				previousPage={events?.previousPage ?? 0}
				columns={columns}
				nextPage={events?.nextPage ?? 0}
				header={
					<div className="w-full mb-3">
						<Filter
							search={search}
							setSearch={setSearch}
							filters={filters}
							onFilter={(payload: EventFilterPayLoad) => {
								setFilters(payload);
							}}
							data={events}
						/>
					</div>
				}
			/>
			<DeleteEventModal
				refresh={setLoading}
				selected={selected}
				open={open}
				setOpen={setOpen}
				title="Delete"
			/>
			<EditEventModal
				refresh={setLoading}
				title={"Edit Event"}
				selected={selected}
				open={openEdit}
				setOpen={setOpenEdit}
			/>
			<StatusEventModal
				refresh={setLoading}
				title={"Update Event Status"}
				selected={selected}
				open={openStatus}
				setOpen={setOpenStatus}
			/>
			<EventDetailsModal
				title={"Event Tickets"}
				selected={selected}
				open={openDetails}
				setOpen={setOpenDetails}
			/>
		</div>
	);
}

type FilterProps = {
	filters: EventFilterPayLoad;
	onFilter: (...args: any) => void;
	search: string;
	setSearch: React.Dispatch<SetStateAction<string>>;
	data: PaginationType<EventType> | null;
};

function Filter(props: FilterProps) {
	const { onFilter, filters, search, setSearch, data } = props;
	const [open, setOpen] = useState(false);
	const [openPrint, setOpenPrint] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const schema = z.object({
		from: z.string().optional(),
		to: z.string().optional(),
		channel: z.string().optional(),
	});
	const defaultFilters: EventFilterPayLoad = {
		from: "",
		to: "",
		channel: "",
	};
	const handleOnSubmit = (payload: any) => {
		const searchParams = new URLSearchParams(location.search);

		const { from, to, channel } = payload;

		if (from) searchParams.set("from", from);
		if (to) searchParams.set("to", to);
		if (channel) searchParams.set("channel", channel);

		const newSearch = searchParams.toString();
		onFilter(payload);
		navigate(`${location.pathname}?${newSearch}`);

		setOpen(false);
	};
	const resetFilter = () => {
		onFilter(defaultFilters);
		navigate(`${location.pathname}`);
		setOpen(false);
	};
	return (
		<>
			<div className="w-full justify-end flex flex-wrap">
				<div className="flex flex-row gap-3">
					<Button title="Filter results" onClick={() => setOpen(true)}>
						<FunnelIcon className="w-4" /> Filter
					</Button>
				</div>
			</div>
			<Modal show={open} setShow={setOpen} title={"Filter"}>
				<Form<EventFilterPayLoad, typeof schema>
					onSubmit={handleOnSubmit}
					schema={schema}
					options={{ defaultValues: filters }}
				>
					{({ register, formState }) => (
						<div className="px-3">
							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<InputField
									type="date"
									error={formState.errors.from}
									registration={register("from")}
									className="h-12"
									label="Start Date"
								/>

								<InputField
									type="date"
									error={formState.errors.to}
									registration={register("to")}
									className="h-12"
									label="End Date"
								/>
							</div>
							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<InputField
									type="number"
									error={formState.errors.channel}
									registration={register("channel")}
									className="h-12"
									label="Channel"
								/>
							</div>
							<div className="py-4 flex flex-row justify-between">
								<Button onClick={resetFilter} background="bg-gray-500">
									Reset
								</Button>
								<Button>Filter</Button>
							</div>
						</div>
					)}
				</Form>
			</Modal>
		</>
	);
}
