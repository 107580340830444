import { InvitationPayload } from "@/app/types/invitations";
import { PRIVATE_API } from "../axios";
import { AxiosErrorHandler, CustomError } from "@/core/utils";
import { StatsType } from "@/app/types/stats";

export const get_stats = async (): Promise<StatsType> => {
    try {
        const request = await PRIVATE_API.get('/event/stats');
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};