import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PRIVATE_API } from '../axios';
import { TicketPayload, Ticket, GenerateTicketPayload } from '@/app/types/tickets';
import { PaginationType } from '@/app/types/shared';
import { queryString } from '@/core/utils';

export const get_tickets = async (query? : string): Promise<PaginationType<Ticket>> => {
    try {
        const request = await PRIVATE_API.get(`/ticket${queryString(query)}${queryString(query) && '&type=offline'}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const get_tickets_exported = async (data : { from: number; to: number, id: string, size: string, description: string }): Promise<PaginationType<Ticket>> => {
    try {
        const request = await PRIVATE_API.get(`/ticket?description=${data.description}&fromId=${data.from}&toId=${data.to}&pageSize=${data.size}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_batch_names = async (): Promise<{ uniqueDescriptions: string[] }> => {
    try {
        const request = await PRIVATE_API.get(`/ticket/unique-descriptions`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const get_tickets_online = async (query? : string): Promise<PaginationType<Ticket>> => {
    try {
        const request = await PRIVATE_API.get(`/ticket${queryString(query)}${queryString(query) && '&type=online'}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_tickets = async (data : TicketPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/ticket', data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_tickets = async (data : TicketPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/ticket/${id}`, data);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_ticket = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/ticket/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const sold_out_ticket = async (id : string): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(`/ticket/soldout/${id}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const generate_tickets = async (data : GenerateTicketPayload): Promise<Ticket[]> => {
    try {
        const request = await PRIVATE_API.post(`/ticket/offline-tickets`, data);
        return await request.data.tickets;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
