import { useAppSelector } from "@/core/redux/store/types";
import { useGetAllStats } from "@/hooks/stats";
import Chart from "react-apexcharts";
import {useEffect, useState} from "react";
import {useGetAllEvents, useGetAllEventsStats} from "@/hooks/events";
import Select from "react-select";

export default function Charts() {
	const { userAccount } = useAppSelector((state) => state).user;
	const { loadingStats, stats } = useGetAllStats();
	const { handleGetEvents, loadingEvents, events } = useGetAllEvents();
	const statList = useGetAllEventsStats();
	const [selectedEvent, setSelectedEvent] = useState(events?.list[0].id ?? '');

	const [val, setVal] = useState('1');

	useEffect(() => {
		handleGetEvents('?pageSize=100');
		if(events && events?.list.length > 0) {
			statList.handleGetStats(selectedEvent);
		}
	}, [selectedEvent]);

	console.log(statList.stats)

	return (
		<div className="min-h-screen">
			<div className="my-3 px-3 overflow-visible grid grid-cols-2 gap-3">
				<Select styles={{ control : (b, s) => ({ ...b, boxShadow: 'none', '&:active': {
							border: '1px solid #00C9C8',
							outline: '1px solid #00C9C8'
						} }) }} options={[
					{ label: 'Online tickets', value: '1' },
					{ label: 'Scanned tickets', value: '2' },
					{ label: 'Offline tickets', value: '3' }
				]} className={''} onChange={(e) => setVal(e?.value ?? '1')} />
				<Select options={events?.list.map((item) => {
					return {
						label: item.title,
						value: item.id,
					}
				})}  placeholder={'Select Events'} onChange={(e) => setSelectedEvent(e?.value ?? '')} isLoading={loadingEvents} className={'focus:ring-primary'} />
			</div>
			<div className="">
				{loadingStats &&
					<div className={'w-full grid grid-cols-1 md:grid-cols-2 gap-3 bg-gray-10  p-3'}>
						<DummyGraph/>
					</div>
				}
			</div>
			<div className="w-full flex items-center justify-start gap-3 bg-gray-10  px-3">
				<div className="w-full md:w-7/12">
					{statList.stats && !loadingStats ? (
						<>
							<Graph
								name="Offline Tickets"
								values={
									val === '1' ? statList.stats.onlineTicket :
										val === '2' ? statList.stats.scannedTickets :
											statList.stats.offlineTicket
								}
								color="#00C9C8"
							/>
						</>
					) : ''}
				</div>
			</div>
		</div>
	);
}

type GraphProps = {
	name: string;
	values: {
		type: string;
		tickets: number;
	}[];
	color: string;
};

function Graph(props: GraphProps) {
	console.log(props.values);
	const isDark = useAppSelector((state) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
    const isRtl = useAppSelector((state) => state.themeConfig.rtlClass) === 'rtl' ? true : false;

	const series = [
		{
			name: "Tickets",
			data: props.values.map((values: any) => values.tickets),
		},
	];

	const options: any = {
		series: series,
		options: {
			title: {
				text: `${props.name} Tickets`,
				style: {
					fontWeight: "normal",
					color: "#00C9C8",
				},
			},
			chart: {
				height: 300,
				type: 'bar',
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			colors: ['#00C9C8', '#e7515a'],
			dataLabels: {
				enabled: false,
			},
			
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '55%',
					endingShape: 'rounded',
				},
			},
			grid: {
				borderColor: isDark ? '#191e3a' : '#e0e6ed',
			},
			xaxis: {
				categories: props.values.map((values: any) => values.type),
				axisBorder: {
					color: isDark ? '#191e3a' : '#e0e6ed',
				},
			},
			yaxis: {
				opposite: isRtl ? true : false,
				labels: {
					offsetX: isRtl ? -10 : 0,
				},
			},
			legend: {
				position: 'bottom',
				offsetY: 40,
			},
			tooltip: {
				theme: isDark ? 'dark' : 'light',
				y: {
					formatter: (value: number) => `${value * 1000} RWF`, // Customize the y-axis value format
				},
			},
		},
	};
	return (
		<div className="relative w-full bg-white rounded shadow-md p-2">
			<Chart options={options.options} series={series} type="bar" />
		</div>
	);
}

function DummyGraph() {
    return (
		<div className="relative h-44 animate-pulse w-full  rounded shadow-md border border-gray-50/20 p-2">
			
		</div>
	);
}
