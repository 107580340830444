import { PaginationType } from '@/app/types/shared';
import { Modal } from '@/components/elements';
import React, { SetStateAction, useState } from 'react';
import { savePDF } from '@progress/kendo-react-pdf';
import Button from '@/components/elements/button';
import { DataTable } from '@/components/elements/tables';
import { EventType } from '@/app/types/events';
import { TableColumn } from '@/components/elements/tables/Datatable';
import DashTicketsTypes from './tickets_type';
import CreateTicketTypes from './tickets_type/create';

type PrintModalProps = {
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	title: string;
	selected: EventType | null;
};

export default function EventDetailsModal({ open, setOpen, title, selected }: PrintModalProps) {
    const [tab, setTab] = useState(0);

    return (
        <Modal size='xl' show={open} setShow={setOpen} title={title}>
            <div className="">
                <div className="flex flex-row">
                    <div onClick={() => setTab(0)} className={`px-2  py-1 ${tab === 0 && 'text-white rounded-t bg-primary'} cursor-pointer`}>Tickets</div>
                    <div onClick={() => setTab(1)} className={`px-2 py-1 ${tab === 1 && 'text-white rounded-t bg-primary'} cursor-pointer`}>Create</div>
                </div>
                {tab === 0 && <div className="">
                    {selected ? <DashTicketsTypes ticket={selected} /> : <div className="flex justify-center items-center">No tickets found.</div> }
                </div>}
                {tab === 1 && <div className="">
                    {selected && <CreateTicketTypes selected={selected} /> }
                </div>}
            </div>
        </Modal>
    );
}
