import { create_tickets_types, delete_ticket_types, edit_tickets_types, get_tickets_types, get_tickets_types_by_event, scan_ticket, sold_out_ticket_types } from '@/api/tickets_types';
import { PaginationType } from '@/app/types/shared';
import { TicketTypePayload, TicketType } from '@/app/types/tickets_types';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export const useGetAllTicketsType = () => {
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [tickets, setTickets] = useState<PaginationType<TicketType> | null>(null);
    const location = useLocation();
    const query = location.search;
    const handleGetTickets = async () => {
        try {
            setLoadingTickets(true);
            const data: PaginationType<TicketType> = await get_tickets_types(query);
            setTickets(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingTickets(false);
        }
    };

    return {
        loadingTickets,
        tickets,
        handleGetTickets
    };  
};

export const useGetAllTicketsTypeByEventId = () => {
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [tickets, setTickets] = useState<PaginationType<TicketType> | null>(null);
    const handleGetTicketsByEvent = async (id: string) => {
        try {
            setLoadingTickets(true);
            const data: PaginationType<TicketType> = await get_tickets_types_by_event(id, '');
            setTickets(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingTickets(false);
        }
    };

    return {
        loadingTickets,
        tickets,
        handleGetTicketsByEvent
    };  
};

export const useCreateTicketsTypes = () => {
    const [loadingCreateTickets, setCreateLoading] = useState(false);

    const handleCreateTickets = async (data: TicketTypePayload) => {
        try {
            setCreateLoading(true);
            await create_tickets_types(data).then(() => toast.success('Ticket Created'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setCreateLoading(false);
        }
    };

    return {
        loadingCreateTickets,
        handleCreateTickets
    };  
};

export const useEditTicketsTypes = () => {
    const [loadingEditTickets, setLoadingEditTickets] = useState(false);

    const handleEditTicket = async (data: TicketTypePayload, id: string) => {
        try {
            setLoadingEditTickets(true);
            await edit_tickets_types(data, id).then(() => toast.success('Ticket Edited'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEditTickets(false);
        }
    };

    return {
        loadingEditTickets,
        handleEditTicket
    };  
};

export const useDeleteTicketTypes = () => {
    const [loadingDeleteTicket, setDeleteLoading] = useState(false);

    const handleDeleteTicket = async (id: string) => {
        try {
            setDeleteLoading(true);
            await delete_ticket_types(id).then(() => toast.success('Ticket Deleted'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setDeleteLoading(false);
        }
    };

    return {
        loadingDeleteTicket,
        handleDeleteTicket
    };  
};

export const useSoldOutTicketTypes = () => {
    const [loadingSoldOut, setSoldOutLoading] = useState(false);

    const handleSoldOutTicket = async (id: string) => {
        try {
            setSoldOutLoading(true);
            await sold_out_ticket_types(id).then(() => toast.success('Ticket Sold Out'));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setSoldOutLoading(false);
        }
    };

    return {
        loadingSoldOut,
        handleSoldOutTicket
    };  
};


export const useScanTicket = () => {
    const [loadingScan, setScanLoading] = useState(false);
    const [scanned, setScanned] = useState<string | null>(null);
    const handleScanTicket = async (id: string) => {
        try {
            setScanLoading(true);
            await scan_ticket(id);
            toast.success('Ticket Scanned')
        } catch (error: any) {
            setScanned(error.response.message);
        } finally {
            setScanLoading(false);
        }
    };

    return {
        loadingScan,
        handleScanTicket,
        scanned
    };  
};