import {UserPayload} from "@/app/types/auth";
import Button from "@/components/elements/button";
import {Form} from "@/components/elements/form";
import {InputField, SelectField} from "@/components/elements/input";
import {useCreateUser} from "@/hooks/auth";
import {z} from "zod";
import {toast} from "react-hot-toast";

const schema = z.object({
    email: z.string().email().min(1, {message: 'Email is required'}),
    name: z.string().min(1, {message: 'Names are required'}),
    phoneNumber: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        {message: 'Phone number must be inform of 2507........'}
    ),
    password: z.string().min(1, {message: 'Password is Required'}),
    role: z.string().min(1, {message: 'Role is required'})
});

export default function CreateUser() {
    const {loadingCreate, handleCreate} = useCreateUser();



    return (
        <div className=" panel">
            <div className="">
                <Form<UserPayload, typeof schema>
                    onSubmit={(data, e) => handleCreate(data).then(() => {
                        e?.target.reset();
                        toast.success('User Created');
                    })}
                    schema={schema}
                >
                    {({register, formState}) => (
                        <div className="w-full">
                            <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                <div className="flex flex-col md:flex-row gap-2">
                                    <InputField
                                        type="email"

                                        isLoading={loadingCreate}
                                        label="Email"
                                        error={formState.errors.email}
                                        registration={register("email")}
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    <InputField
                                        type="text"
                                        isLoading={loadingCreate}
                                        label="Names"
                                        error={formState.errors.name}
                                        registration={register("name")}
                                    />
                                    <InputField
                                        type="text"
                                        isLoading={loadingCreate}
                                        label="Phone number"
                                        error={formState.errors.phoneNumber}
                                        registration={register("phoneNumber")}
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    <InputField
                                        label="Password"
                                        isLoading={loadingCreate}
                                        type="password"
                                        error={formState.errors.password}
                                        registration={register("password")}
                                    />
                                    <SelectField
                                        placeholder="Select Role"
                                        isLoading={loadingCreate}
                                        label="Role"
                                        error={formState.errors.role}
                                        options={[
                                            {
                                                value: 'admin',
                                                text: 'Admin'
                                            },
                                            {
                                                value: 'organizer',
                                                text: 'Organizer'
                                            },
                                            {
                                                value: 'client',
                                                text: 'Client'
                                            },
                                        ]}
                                        registration={register("role")}
                                    />
                                </div>
                                <div className="my-3 flex justify-end">
                                    <Button disabled={loadingCreate}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
}
