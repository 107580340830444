import Button from '@/components/elements/button';
import { useNavigate } from 'react-router-dom';
import notFoundImage from '../../../assets/images/404.png';

export default function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <div className="dot-bg absolute pt-20 z-[999999] flex justify-center items-start bg-gray-100 text-black left-0 right-0 bottom-0 top-0">
            <div className="p-10 w-11/12 max-w-[800px] flex flex-col gap-4 justify-center items-center  border-gray-300/70 rounded-md">
                {/* <p className="text-[40px]">404</p> */}
                <div className="flex flex-col justify-center items-center gap-4">
                    <img src={notFoundImage} className='drop-shadow-2xl w-80' alt="Page Not found" />
                    <p>Page Not Found</p>
                    <button className='py-1 px-2 bg-primary text-white rounded shadow-sm' onClick={() => navigate('/')}>Go Back</button>
                </div>
            </div>
        </div>
    );
}
  