import {
    create_event,
    delete_event,
    edit_event, get_event_stats,
    get_events,
    get_events_by_slug,
    get_stats,
    update_status_event
} from '@/api/events';
import { EventPayload, EventType } from '@/app/types/events';
import { PaginationType } from '@/app/types/shared';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {StatsType} from "@/app/types/stats";

export const useGetAllEvents = () => {
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [events, setEvents] = useState<PaginationType<EventType> | null>(null);
    const handleGetEvents = async (query?: string, search?: string) => {
        try {
            setLoadingEvents(true);
            const data: PaginationType<EventType> = await get_events(query);
            setEvents(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvents(false);
        }
    };

    return {
        loadingEvents,
        events,
        handleGetEvents
    };  
};

export const useGetAllEventsStats = () => {
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [stats, setStats] = useState<StatsType | null>(null);
    const handleGetStats = async (id: string) => {
        try {
            setLoadingEvents(true);
            const data: StatsType = await get_event_stats(id);
            setStats(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvents(false);
        }
    };

    return {
        loadingEvents,
        stats,
        handleGetStats,
    };
};

export const useCreateEvent = () => {
    const [loadingCreateEvent, setLoadingCreateEvent] = useState(false);

    const handleCreateEvent = async (payload: EventPayload) => {
        console.log(payload)
        try {
            if(payload.image === '') {
                throw 'Event Image is required';
            } else {
                setLoadingCreateEvent(true);
                return await create_event(payload).then((data) => {
                    toast.success('Event Created');
                    return data.id as string;
                });
            }
        } catch (error: any) {
            toast.error(error.response?.message || 'Something went wrong');
        } finally {
            setLoadingCreateEvent(false);
        }
    };

    return {
        handleCreateEvent,
        loadingCreateEvent
    };

};

export const useEditEvent = () => {
    const [loadingEditEvent, setLoadingEditEvent] = useState(false);
    const handleEditEvent = async (payload: EventPayload, id: string) => {
        try {
            setLoadingEditEvent(true);
            edit_event(payload, id).then(() => toast.success('Event Edited'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditEvent(false);
        }
    };

    return {
        handleEditEvent,
        loadingEditEvent
    };

};

export const useDeleteEvent = () => {
    const [loadingDeleteEvent, setLoadingDeleteEvent] = useState(false);
    const handleDeleteEvent = async (id: string) => {
        try {
            setLoadingDeleteEvent(true);
            delete_event(id).then(() => toast.success('Event Deleted'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingDeleteEvent(false);
        }
    };

    return {
        handleDeleteEvent,
        loadingDeleteEvent
    };

};

export const useStatusEvent = () => {
    const [loadingStatusEvent, setLoadingStatusEvent] = useState(false);
    const handleStatusEvent = async (id: string) => {
        try {
            setLoadingStatusEvent(true);
            update_status_event(id).then(() => toast.success('Event Status updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingStatusEvent(false);
        }
    };

    return {
        handleStatusEvent,
        loadingStatusEvent
    };

};

export const useGetEventsBySlug = () => {
    const [loadingEvent, setLoadingEvent] = useState(false);
    const [event, setEvent] = useState<EventType | null>(null);
    const { slug } = useParams();
    const navigate = useNavigate();
    console.log(slug);
    if (!slug) {
        navigate(0)
    }
    const handleGetEvent = async () => {
        try {
            setLoadingEvent(true);
            const data: EventType = await get_events_by_slug(slug || '');
            setEvent(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvent(false);
        }
    };

    useEffect(() => {
        handleGetEvent();
    }, [])

    return {
        loadingEvent,
        event,
    };  
};