import { get_stats } from "@/api/stats";
import { StatsType } from "@/app/types/stats";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

export const useGetAllStats = () => {
    const [loadingStats, setLoadingStats] = useState(false);
    const [stats, setStats] = useState<StatsType | null>(null);
    const handleGetStats = async () => {
        try {
            setLoadingStats(true);
            const data: StatsType = await get_stats();
            setStats(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingStats(false);
        }
    };

    useEffect(() => {
        handleGetStats();
    }, [])

    return {
        loadingStats,
        stats,
    };  
};