import { payment } from "@/api/payment";
import { PaymentPayload } from "@/app/types/payment";
import { useState } from "react";
import toast from "react-hot-toast";

export const usePay = () => {
    const [loadingPay, setLoadingPay] = useState(false);
    const handlePay = async (data: PaymentPayload) => {
        try {
            setLoadingPay(true);
            payment(data).then(() => toast.success('Payment Initiated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingPay(false);
        }
    };

    return {
        handlePay,
        loadingPay
    };

};