import { AuthLoginPayload, UserPayload, UserType } from '@/app/types/auth';
import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PRIVATE_API } from '../axios';
import { queryString, searchString } from '@/core/utils';

import { PaginationType } from '@/app/types/shared';

export type RegisterUserPayload = {
    access_token: string;
};

export type LoginUserPayload = {
    access_token: string;
};

export const register_user = async (
    memberPayload: UserPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/user', {
            ...memberPayload,
            username: memberPayload.email,
        });
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const check_user = async (): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get('/user/check');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_user = async (id: string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/user/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_users = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/user${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const login_user = async (
    userCredentials: AuthLoginPayload
): Promise<LoginUserPayload | any> => {
    try {
        const request = await PRIVATE_API.post('/user/login', userCredentials);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
